<template>
  <div>
    <section class="footer">
      <div class="container">
        <div class="col-12 footer_box T18px_Regular colorfff flex-w">
          <div class="item_text"  @click="routerBtn('/')">{{ $t("globalHeader.home")}}</div>

          <div class="item_text"  @click="routerBtn('/servicehome')">{{ $t("globalHeader.service")}}</div>
          <div class="item_text"  @click="routerBtn('/case')">{{ $t("globalHeader.case")}}</div>
          <div class="item_text"  @click="routerBtn('/blolist')">{{ $t("globalHeader.blog")}}</div>
          <div class="item_text"  @click="routerBtn('/contact')">{{ $t("globalHeader.contact")}}</div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-center T18px_Regular text-B7 biaoshi" style="">
              © 2024 Design Agency.
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
  data() {
    return {
      index: null,
    };
  },
  methods: {
    indexChange(index) {
      if (this.index != index) {
        this.index = index;
      } else {
        this.index = null;
      }
    },
    /**
     * 滚动到指定位置
     */
    goAssignBlock(el, speed, url, query) {
      let path = this.$route.path;

      if (path.indexOf("url") == -1) {
        this.$router.push({ path: url, query: query });
      }

      this.$emit("goAssignBlock", el, speed, url);
    },
    routerBtn(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.h5_footer {
  display: none;
}

.footer {
  padding-bottom: 1.0313rem;
  background: #15171d;
}

.footer_box {
  border-bottom: 0.0313rem solid #ffffff;
  padding-bottom: 0.9375rem;
  justify-content: flex-end;
  padding-right: 0.625rem;

  .item_text {
    margin-left: 3.2813rem;
  }
}

.biaoshi {
  margin-top: 1.1875rem;
}

@media (min-width: 200px) and (max-width: 768px) {
  .footer_box {
    padding-top: 0.625rem;
  }

  .item_text {
    margin-left: 1.6813rem !important;
  }

  .biaoshi {
    margin-top: 0.6875rem;
  }
}
</style>
