module.exports = {
  mesg: "Congratulations on your successful submission. We will contact you within 24 hours",
  globalHeader: {
    home: "Home",
    service: "Service",
    servicechil1: "Customized development/Design",
    servicechil2: "One stop shop construction",
    case: "Case",
    blog: "Blog",
    contact: "Contact Us",
    language: "中文",
  },
  home: {
    headerTitle: "Tech as sail, forging ahead.",
    projectListInfo: "View Project",
    projectListInfoTitle1: "MoXun",
    projectListInfoTips1:
      "A fitness app that combines personal trainer booking with gamified workouts, offering a personalized, engaging, and motivating experience. It helps users improve their fitness level, meet gym partner and obtain customized support—all in one place.",
    projectListInfoTitle2: "Ninja X",
    projectListInfoTips2:
      "An American Ninja X Warrior-style training center that unlocks children's potential and builds future leaders through innovative training focused on their growth and confidence.",
    projectListInfoTitle3: "Shang Hai",
    projectListInfoTips3:
      "An app platform that encourages children and teens to reconnect with nature through outdoor play, helping them rediscover the joys of childhood and sports.",
    scalingText:
      "We offer customized software development, standalone website creation, and brand design services. By developing practical business strategies and integrating internet technology with innovative design, we are committed to creating unique and competitive digital solutions for our clients and their brands.",
    flowTitle1_a: "User",
    flowTitle1_b: "Experience",
    flowTitle2_a: "Vision",
    flowTitle2_b: "Design",
    flowTitle3_a: "Cont",
    flowTitle3_b: "ent",
    flowTitle4_a: "User",
    flowTitle4_b: "Testing",
    flowTitle1_text:
      "We prioritize both your experience and, more importantly, your users' experience. Your goals are our goals. Our team dives deep into understanding your needs, following a tailored process to ensure a great user experience and project success. We bring our full expertise to building a strong foundation together.",
    flowTitle1_chile1: "Client Relations",
    flowTitle1_chile2: "User Research",
    flowTitle1_chile3: "Market Analysis",
    flowTitle1_chile4: "Wireframe",
    flowTitle2_text:
      "Visuals are a powerful element in digital product development. They shape first impressions and directly impact user experience. Our goal is to attract users and boost conversion rates. Through thoughtfully crafted visuals, we convey your brand's identity and values, creating a true reflection of your company’s image.",
    flowTitle2_chile1: "Visual Style",
    flowTitle2_chile2: "Motion Design",
    flowTitle2_chile3: "UI Design",
    flowTitle3_text:
      "In an age of information overload, we create clear, engaging content that helps users find what they need quickly, improving their experience. We use rich, tailored content formats that set you apart and avoid sameness. With a precise strategy, we ensure consistent, accurate messaging, working with you to build a distinctive brand identity.",
    flowTitle3_chile1: "Copywriting",
    flowTitle3_chile2: "Layout Design",
    flowTitle3_chile3: "Creative Design",
    flowTitle4_text:
      "Test development is key to stability and user experience. By collaborating closely between frontend and backend teams, we turn design and requirements into reliable code. Testing ensures product quality by quickly identifying and fixing issues. Plus, we offer two years of support to keep your product running smoothly.",
    flowTitle4_chile1: "Frontend Dev",
    flowTitle4_chile2: "Backend Dev",
    flowTitle4_chile3: "Product QA",
    flowTitle4_chile4: "Support & Maintenance",
    right_text: `Our<br />Services`,
    blog_left_tips: `News<br />Blog`,
    blog_title1: "Explore",
    blog_title2: "Insights,Industry Focus",
    blog_list1_time: "August 18, 2024",
    blog_list1_title:
      "Starting Online: How to Create a Memorable Brand Identity",
    blog_list2_time: "September 10, 2024",
    blog_list2_title:
      "Corporate Website Planning: How to Drive B2B Growth with a Precise Content Strategy",
    blog_list3_time: "October 1, 2024",
    blog_list3_title:
      "User Experience to Data Analysis: A Guide to Building a Successful B2C Website",
    gengduo: "Read More",
    gengduos: "More Articles",
  },
  // 服务
  servicehome: {
    header_title1: "User-",
    header_title2: "Centered,",
    header_title3: "Brand-",
    header_title4: "Focused,",
    header_title5: "Strategy-",
    header_title6: "Driven,",
    header_title7: "Customized ",
    header_title8: "Product Services",

    software_title1: "Custom ",
    software_title2: "Software",
    software_chil1: "App Dev",
    software_chil2: "WeChat Mini-App",
    software_chil3: "Office Management",
    software_chil4: "Custom Software",
    software_chil5: "Cross-Platform",
    software_chil6: "Third-Party",

    website_title1: "Custom Website",
    website_title2: "Development",
    website_chil1: "App Dev",
    website_chil2: "WeChat Mini-App",
    website_chil3: "Office Management",
    website_chil4: "Custom Software",
    website_chil5: "Cross-Platform",
    website_chil6: "Third-Party Support",

    brand_title1: "Brand",
    brand_title2: "Design",
    brand_chil1: "Brand Positioning",
    brand_chil2: "Brand Identity",
    brand_chil3: "Brand Logo/Tagline",
    brand_chil4: "Visual Identity (VI)",
    brand_chil5: "Brand Integration",
    brand_chil6: "Brand Strategy",

    touch_title1: "One-Stop",
    touch_title2: "Solution",
    touch_chil1: "Web Development",
    touch_chil2: "Software/Mini-App",
    touch_chil3: "Brand Marketing",

    ljie: "30-Second Overview",
  },

  // 服务-软件开发
  software: {
    title1: "Develop ",
    title2: "Software",
    header_title_tips:
      "We provide a one-stop solution to help build your brand from 0 to 1. From initial concept to launch, we ensure a smooth transition from idea to implementation, guiding your software through its journey to initial success. With 100% dedication and expertise, we work with you to lay the strongest foundation possible.",
    stepArrList1_name: "Client",
    stepArrList1_names: "Consult",
    stepArrList1_text:
      "Thank you for submitting your inquiry on our website! Our business manager will reach out to you within 24 hours to schedule a meeting and discuss your project needs and business goals in detail. Following that, our solution specialist will provide you with a preliminary customized plan, including the estimated timeline and cost information.",
    stepArrList2_name: "Co-",
    stepArrList2_names: "Creation",

    stepArrList2_text:
      "The project officially kicks off with the primary task of gathering your requirements. Together, we will explore and discuss your website goals, desired features, and content strategy. Our product manager will oversee the website architecture design, ensuring that from the initial planning stages, everything aligns closely with your business objectives.",
    stepArrList3_name: "Custom",
    stepArrList3_names: "Design",
    stepArrList3_text:
      "We will prepare a detailed development requirement document, covering all the information needed by designers and developers. The product manager and UI/UX designer will work together to complete the prototype and interface design for your customized website. Once you have confirmed everything is in order, we will proceed to the development phase.",

    stepArrList4_name: "Deve",
    stepArrList4_names: "lopment",
    stepArrList4_text:
      "Front-end developers will transform design mockups into actual website interfaces and interactions, ensuring compatibility across various screen sizes. Back-end developers will build the website's back-end logic and functionality according to the requirements and design plan, providing support for data processing and business logic.",

    stepArrList5_name: "Quality",
    stepArrList5_names: "Assurance",
    stepArrList5_text:
      "We’ll deliver all required documents, including the final code and design, to the client. Our thorough testing ensures a high-quality product. Plus, our two-year after-sales support keeps everything running smoothly.",

    stepArrList6_name: "Deploy",
    stepArrList6_names: "Launch",
    stepArrList6_text:
      "The final project will be reviewed and accepted together with you. After acceptance, our developers will handle the deployment of your program to the server, ensuring a smooth launch and accessibility. We will take care of domain configuration, server setup, and other related tasks.",

    stepArrList7_name: "Support",
    stepArrList7_names: "&Maint.",
    stepArrList7_text:
      "After project completion, we will hand over all project files and source code to you. Additionally, to ensure ongoing service quality and project follow-up, we provide unconditional technical support and brand strategy consultation for two years.",
  },

  // 服务-网站软件
  website: {
    title1: "Website ",
    title2: "Software",
    header_title_tips:
      "We provide a one-stop solution to build your brand from 0 to 1. Ensuring a smooth transition from concept to launch, we help you move from zero to initial success. With 100% dedication and expertise, we work with you to lay the strongest foundation possible.",
    stepArrList1_name: "Client",
    stepArrList1_names: "Consult",
    stepArrList1_text:
      "Thank you for submitting your inquiry on our website! Our business manager will reach out to you within 24 hours to schedule a meeting and discuss your project needs and business goals in detail. Following that, our solution specialist will provide you with a preliminary customized plan, including the estimated timeline and cost information.",
    stepArrList2_name: "Research",
    stepArrList2_names: "&Planning",
    stepArrList2_text:
      "The project officially kicks off with the primary task of gathering your requirements. Together, we will explore and discuss your website goals, desired features, and content strategy. Our product manager will oversee the website architecture design, ensuring that from the initial planning stages, everything aligns closely with your business objectives.",
    stepArrList3_name: "Custom",
    stepArrList3_names: "Design",
    stepArrList3_text:
      "We will prepare a detailed development requirement document, covering all the information needed by designers and developers. The product manager and UI/UX designer will work together to complete the prototype and interface design for your customized website. Once you have confirmed everything is in order, we will proceed to the website development phase.",
    stepArrList4_name: "Web",
    stepArrList4_names: "Dev.",
    stepArrList4_text:
      "Front-end developers will transform design mockups into actual website interfaces and interactions, ensuring compatibility across various screen sizes. Back-end developers will build the website's back-end logic and functionality according to the requirements and design plan, providing support for data processing and business logic.",
    stepArrList5_name: "Product ",
    stepArrList5_names: "Testing",
    stepArrList5_text:
      "We’ll deliver all required documents, including the final code and design, to the client. Our thorough testing ensures a high-quality product. Plus, our two-year after-sales support keeps everything running smoothly.",
    stepArrList6_name: "Deploy",
    stepArrList6_names: "Launch",
    stepArrList6_text:
      "The final project will be reviewed and accepted together with you. After acceptance, our developers will handle the deployment of your program to the server, ensuring a smooth launch and accessibility. We will take care of domain configuration, server setup, and other related tasks.",
    stepArrList7_name: "Support",
    stepArrList7_names: "&Maint.",
    stepArrList7_text:
      "After project completion, we will hand over all project files and source code to you. Additionally, to ensure ongoing service quality and project follow-up, we provide unconditional technical support and brand strategy consultation for two years.",
  },

  //服务-品牌设计
  brand: {
    title1: "Brand ",
    title2: "Design",
    header_title_tips:
      "We support your brand's journey from 0 to 100, ensuring long-term success. From initial development to full-scale expansion, we help you achieve sustained growth and success in the market. Our focus extends beyond the brand's early stages—we are committed to guiding you through brand maturity, expansion, and optimization, ensuring a solid market position and lasting impact.",
    stepArrList1_name: "Client ",
    stepArrList1_names: "Consult",
    stepArrList1_text:
      "Thank you for submitting your inquiry on our website! Our business manager will reach out to you within 24 hours to schedule a meeting and discuss your project needs and business goals in detail. Following that, our solution specialist will provide you with a preliminary customized plan, including the estimated timeline and cost information.",
    stepArrList2_name: "Research",
    stepArrList2_names: "&Strategy",
    stepArrList2_text:
      "We support your new brand strategy through in-depth research. By conducting internal evaluations, customer and competitor analysis, and stakeholder surveys, we gather all the necessary information to build a strong foundation for shaping your new brand identity.",
    stepArrList3_name: "Tone",
    stepArrList3_names: "&Mood",
    stepArrList3_text:
      "We delve into brand design options, carefully crafting color palettes, logos, and other core elements, seamlessly integrating mood and visual tone. Through this design process, we ensure every detail conveys the emotional essence of your brand, enhancing its visual appeal and memorability.",
    stepArrList4_name: "Brand",
    stepArrListy4_names: "Delivery",
    stepArrList4_text:
      "After final agreement, we will deliver the completed assets and prepare for your new brand's launch. The final deliverables will include all necessary resources, such as materials suitable for print and digital platforms, brand usage guidelines, and detailed specifications. Additionally, we provide solutions to common technical issues, ensuring a smooth brand launch.",
    stepArrList5_name: "Support",
    stepArrList5_names: "&Maint.",
    stepArrList5_text:
      "After the project is complete, we will hand over all project materials to you. Additionally, to ensure continued service quality and project follow-up, we offer unconditional brand strategy consultation for two years.",
  },

  // 案例
  case: {
    title1: "Riding the Waves,",
    title2: "A Digitally Driven Future",
    rigth_text_box:
      "In the fast-changing waves of technology, we focus on creating exceptional user experiences through innovative digital development. With a global perspective and technical expertise, we not only navigate the latest tech trends but also push boundaries, building cutting-edge solutions that help your brand stand out in a competitive market and achieve long-term success.",
    menu_btn1: "Custom Development/Design",
    menu_btn2: "One-Stop Solution",
    projecList1_name: "Mo Xun",
    projecList1_tips: "Making Training as Fun as Magic",
    projecList1_child1: "Custom Software",
    projecList1_child2: "Mobile Development",
    projecList2_name: "Shang Hai",
    projecList2_tips: "Explore Nature's Power, Ignite Passion for Movement",
    projecList2_child1: "Custom Mini Programs",
    projecList2_child2: "Mobile Development",
    projecList3_name: "Academy Management",
    projecList3_tips:
      "Smart Academy, Efficient and Convenient Management System",
    projecList3_child1: "Custom Programs",
    projecList3_child2: "Mobile Development",
    projecList4_name: "Ninja X",
    projecList4_tips: "Train like a ninja, Play like a pro",
    projecList4_child1: "Brand Customization",
    projecList4_child2: "VI Visual System",
  },

  // 案例 -魔训
  moXun: {
    headerTitle: "Mo Xun",
    jb_title1: "Fitness & Personal Training",
    jb_title2: "Program Dev",
    left_box_tips:
      "Mo Xun, a fitness firm specializing in personal training, approached us with founder Ran Ming's vision to create an innovative online platform. This platform connects users, trainers, and gyms, integrating gamified workouts, personalized advice, and an interactive community. It makes fitness fun, sustainable, and enjoyable, thereby boosting user engagement, consistency, and satisfaction.",
    headerList1_title: "Services",
    headerList1_chlie1: "Mini Program & App Dev",
    headerList1_chlie2: "User Experience",
    headerList1_chlie3: "Software Maintenance",
    headerList1_chlie4: "Technical Decisions",

    headerList2_title: "Platforms",
    headerList2_chlie1: "Android",
    headerList2_chlie2: "iOS",
    headerList2_chlie3: "Uniapp",

    headerList3_title: "Tools&Tech",
    headerList3_chlie1: "Gaode Map API",
    headerList3_chlie2: "E-commerce Payments",
    headerList3_chlie3: "One-Click Login",

    project_bj_title1: "Project",
    project_bj_title2: "Background",
    project_bj__p1: "Breaking Tradition, Leading Innovation: Mo Xun's Vision",
    project_bj__p1s:
      "In today’s crowded fitness market, the traditional gym model of bulk class purchases and memberships has left many consumers tired and disengaged. People now seek a more personalized, flexible, and enjoyable fitness experience—exactly what Mo Xun (MOTION) aims to deliver. This software aspires to be a guiding light in a saturated market, leading the next wave of fitness and reshaping how people view exercise.",
    project_bj__p2: "Innovative Ideas: The Birth of Mo Xun",
    project_bj__p2s:
      "Ran Ming, the founder of Mo Xun and former Regional Manager of Wales Fitness Center in the Southwest, teamed up with the Blackwave team for extensive brainstorming and user testing. Together, they refined Mo Xun into its latest version—an app that seamlessly combines fun, professionalism, convenience, and self-discipline for a better fitness experience.",

    challenge_title1: "Market",
    challenge_title2: "Analysis",
    challenge_p1:
      "We identified six key challenges users face in the fitness industry, spanning sales, user experience, economic, and psychological factors. Tackling these issues requires innovation and improvements in product design, service delivery, and user experience to meet the demand for personalization, convenience, and value.",
    challenge_p1_chil1: "1.0 Sales-Driven Personal Trainers",
    challenge_p1_chil2: "2.0 Restrictions of Time and Location",
    challenge_p1_chil3: "3.0 Low Motivation, Dull Workouts",
    challenge_p1_chil4: "4.0 Lack of Personalized Programs",
    challenge_p1_chil5: "5.0 Costly Training Packages",
    challenge_p1_chil6: "6.0 Lack of Motivation and Goals",

    derive_header1: "Derived",
    derive_header2: "Solutions",

    deriveList1_title: "1.0 Data Insights | Trainer Network",
    deriveList1_infoText:
      "The big data analysis feature suggests the most suitable trainers based on data tags and user input. It also includes a trainer community feature, making it easier for users to browse and choose their preferred coaches.",
    deriveList2_title: "2.0 Online Booking Function",
    deriveList2_infoText:
      "Our online smart training feature offers the convenience of working out without leaving home. We developed an appointment feature, enabling users to book personal training sessions online and choose nearby facilities for more intensive training.",
    deriveList3_title: "3.0 Gamified Themed Fitness Workouts",
    deriveList3_infoText:
      "According to the American Exercise Association, adults who train less than 150 minutes per week are considered sedentary. To combat this, we developed a daily themed fitness feature, allowing users to complete a workout in just 9 minutes. Blackwave aims to encourage more users to adopt regular exercise through Mo Xun.",
    deriveList4_title: "4.0 Personalized Program Matching",
    deriveList4_infoText:
      "Focusing on user training needs, Blackwave collaborated with professional Mo Xun coaches to design a personalized program matching system. It creates tailored training plans based on user selections while ensuring exercises are suitable, avoiding potential injuries.",
    deriveList5_title: "5.0 Online Purchase Store",
    deriveList5_infoText:
      "Users can purchase single personal training sessions without the pressure of upselling. Train whenever you want and book personal training sessions as needed.",
    deriveList6_title: "6.0 Progress Tracking / Buddy",
    deriveList6_infoText:
      "Users can upload body data and post-workout photos for comparison at any time. Through our research, many users found that having a workout buddy for mutual support significantly improved their progress. The Mo Xun app includes a workout buddy feature, helping users find like-minded fitness partners for motivation and support.",

    carouselList1_title: "Tailored Training Plans through Data Insights",
    carouselList2_title: "Tailored Training Plans through Data Insights",

    project_summarize1: "Project ",
    project_summarize2: "Summary",
    project_summarize_proj_bj_text:
      "The Mo Xun app, designed by Blackwave, has broken traditional fitness models through innovation, offering users a personalized, convenient, and flexible fitness experience. Combining smart personal training services, a personalized program matching system, and daily fitness check-ins, Mo Xun meets the diverse needs of modern users, redefining the future direction of the fitness industry.",

    genduo: "More Client Cases",
  },

  // 案例 -山海传奇
  sanhaiJing: {
    headerTitle: "Shang Hai",
    jb_title1: "Youth & Kids' Fitness",
    jb_title2: "Program Dev",
    left_box_tips:
      "The platform aims to help children and teens reconnect with nature through active play and helping others, rediscovering the joys of childhood. With a customized mini program, it provides an easy booking process, secure supervision, and guidance, enriching the parent-child experience.",
    headerList1_title: "Services",
    headerList1_chlie1: "Mini Program & App Dev",
    headerList1_chlie2: "User Experience",
    headerList1_chlie3: "Software Maintenance",
    headerList1_chlie4: "Technical Decisions",

    headerList2_title: "Platforms",
    headerList2_chlie1: "Uniapp",
    headerList2_chlie2: "Web Design",

    headerList3_title: "Tools&Tech",
    headerList3_chlie1: "Gaode Map API",
    headerList3_chlie2: "E-commerce Payments",
    headerList3_chlie3: "One-Click Login",

    project_bj_title1: "Project",
    project_bj_title2: "Background",

    project_bj_p1:
      "In today's digital age, children and teens spend increasingly more time on electronic devices, with limited opportunities for outdoor activities and connecting with nature. To help them rediscover the innocence and spirit of exploration from their childhood, we developed the Shang Hai obstacle course platform. This project aims to provide kids with a fun and challenging outdoor environment where they can run, jump, and play in nature while fostering teamwork and a spirit of helping others.",
    project_bj_p2:
      "After extensive discussions with the client, we invested significant time in thorough research and planning to minimize risks in the early stages. Based on their needs, we customized a mini program that integrates [Registration Delivery], [Competition Records], [Safety Supervision], and [Parent-Child Interaction].",

    challenge_title1: "Project",
    challenge_title2: "Challenges",
    challenge_p1:
      "During the development of the Shang Hai obstacle course platform, the main technical challenges faced by the client included QR code generation and binding for participants, uploading and managing competition results, and generating ranking posters for user notifications. Through innovative technical solutions, we successfully addressed these challenges.",
    challenge_p2_title:
      "1.0 Disorganized venues hindered quick result tracking and communication to participants post-competition",
    challenge_p2:
      "By implementing QR code technology, we enabled automatic binding between participants and helmet devices. Upon successful registration, each participant receives a unique QR code, which can be sent through the app or email and is linked in real-time to their helmet.",
    challenge_p3_title: "2.0 Difficulty in data collection, prone to errors",
    challenge_p3:
      "After each competition, the system automatically collects participant data and uploads a detailed file to the backend management system. To ensure accuracy and security, we utilized advanced data encryption and automated uploading processes. This not only reduced the administrative burden but also ensured efficient and secure data transmission.",
    challenge_p4_title:
      "3.0 Lack of participant interaction and brand visibility",
    challenge_p4:
      "To enhance the user experience, the system immediately notifies participants of their results after the competition, providing detailed ranking information. Participants can receive notifications through the app and view their complete rankings. Additionally, we implemented a unique feature: ranking poster generation. Participants can easily create personalized ranking posters to share on social media, celebrating their achievements with friends and family. This feature not only increased user engagement and interaction but also provided added value for the client’s brand promotion.",

    carouselList1_title:
      "Myth-inspired levels spark creativity, while the platform streamlines post-competition follow-up, boosting user interaction.",

    project_summarize1: "Project",
    project_summarize2: "Summary",
    project_summarize_proj_bj_text:
      "Through the development of the Shang Hai platform, the client company can now plan and schedule competitions over the long term while addressing multiple business challenges. This comprehensive, innovative, and efficient solution achieves technical and design breakthroughs while setting new benchmarks in safety, user experience, and market performance. Blackwave remains dedicated to optimizing the platform, supporting its long-term growth, and creating more value for children and families.",
  },

  // 案例 -学院管理
  college: {
    headerTitle: "Academy Management",
    jb_title1: "Office Management",
    jb_title2: "Software Development",
    left_box_tips:
      "Streamline new student registration, managing data, class assignments, adjustments, registration, payments, and reporting. Automate registration forms and admission notices with single or batch printing. Multi-platform management includes PC, student, teacher, logistics, and academy interfaces.",
    headerList1_title: "Services",
    headerList1_chlie1: "Mini Program & App Dev",
    headerList1_chlie2: "User Experience",
    headerList1_chlie3: "Software Maintenance",
    headerList1_chlie4: "Technical Decisions",

    headerList2_title: "Platforms",
    headerList2_chlie1: "Java-Ruoyi",
    headerList2_chlie2: "Backend Management",

    headerList3_title: "Tools&Tech",
    headerList3_chlie1: "Gaode Map API",
    headerList3_chlie2: "E-commerce Payments",
    headerList3_chlie3: "One-Click Login",

    project_bj_title1: "Project",
    project_bj_title2: "Background",
    project_bj_p1:
      "With the continuous advancement of educational informatization, college enrollment and student management are increasingly moving toward digitalization and intelligence. Traditional methods of enrollment and management face numerous challenges, such as errors in manual data entry, inefficient manual class assignments, and delays in information sharing, making it difficult to meet the needs of modern colleges for efficient management and high-quality service. To address these challenges, we developed a new student registration and management system.",

    project_bj2_title1: "Why Choose",
    project_bj2_title2: "a Management System?",
    project_bj2_p1:
      "Customizing an OA (Office Automation) system tailored to the specific needs of an enterprise can significantly improve work efficiency and collaboration. By designing the system to align closely with actual work processes, unnecessary complexities are reduced. A customized system also provides higher data security and compliance, as well as flexibility and scalability to support ongoing business growth. This not only simplifies employee operations and increases satisfaction but also lowers long-term costs, strengthening the company's competitive edge. A customized OA system is a key tool for enhancing management capabilities and market competitiveness.",
    project_bj2_p2_title: "1.0 Improve Work Efficiency",
    project_bj2_p2:
      "The management system software automates many daily tasks, such as data entry, class assignments, class adjustments, and statistical analysis. This significantly reduces time spent on manual tasks and labor costs, improving overall work efficiency.",
    project_bj2_p3_title: "2.0 Enhance Data Accuracy",
    project_bj2_p3:
      "With systematic management, data entry, storage, and processing become more accurate, reducing human errors and ensuring data accuracy. This is particularly crucial for managing student information, registration records, and financial data.",
    project_bj2_p4_title: "3.0 Simplify Management Processes",
    project_bj2_p4:
      "The system can generate various reports and analytics in real-time, providing valuable data to support school decision-making. For instance, it can quickly display registration numbers, class distribution, and payment statuses, facilitating more informed management decisions.",
    project_bj2_p5_title: "4.0 Enhance Security",
    project_bj2_p5:
      "The software typically includes robust data security measures to prevent data breaches and loss. With role-based access controls, different users can access and manage only the data relevant to their roles, ensuring the security and confidentiality of information.",

    project_summarize1: "Project",
    project_summarize2: "Summary",
    project_summarize_proj_bj_text:
      "In conclusion, the digitalization of online registration supports the transformation and upgrading of the education sector through simplified processes, improved data accuracy, streamlined payment, reduced workload, and the realization of 'one-stop online services' along with personalized services.",
  },
  // 案例 - 忍者X 学院
  ninja: {
    headerTitle: "Ninja X",
    jb_title1: "Youth & Children's Fitness",
    jb_title2: "Brand Design",
    left_box_tips:
      "Ninja X is a pioneering training center in Vancouver, Canada, focused on unlocking children's potential and nurturing future leaders. The founder partnered with us to create a comprehensive brand solution that showcases their dynamic training programs and supports children's holistic development. By integrating innovative Ninja X elements, we aim to shape every aspect of the project, advancing the academy's mission to inspire and empower every child.",
    headerList1_title: "Services",
    headerList1_chlie1: "Visual Identity Design",
    headerList1_chlie2: "Design Consulting",
    headerList1_chlie3: "Brand Operations",
    headerList1_chlie4: "Technical Decisions",

    headerList2_title: "Platforms",
    headerList2_chlie1: "Physical Operations",

    headerList3_title: "Tools&Tech",
    headerList3_chlie1: "3D Modeling & Rendering",

    project_bj_title1: "Project",
    project_bj_title2: "Background",
    project_bj_p1:
      "NINJA X is a children's skills development and training center located in Richmond, Vancouver, Canada. With its unique and playful obstacle courses and parkour-inspired designs, it aims to enhance children's physical abilities while stimulating their mental growth.",
    project_bj_p2:
      "In the initial phase of establishing the facility, Blackwave was appointed as the brand identity design partner, responsible for developing the brand’s primary colors, facility visual design, logo, and advertising campaign designs. Through innovative and professional design, we crafted a vibrant and appealing brand image.",

    project_bj1_header: "From 0-",
    project_bj2_header: "1",
    project_bj1_p1:
      "During the early design phase, we conducted an on-site inspection of the space, finding large areas of light wood tones with bright and natural lighting. After careful consideration, we decided to retain the original floor tones to maintain a warm and inviting atmosphere.",
    project_bj1_p2_1:
      "Given that the primary audience for this brand is children under 10 years old, we aimed to choose playful and vibrant colors that attract children's attention and spark their interest. After thorough research on ninja-themed elements and color psychology, we combined these insights to finalize the brand's primary colors: green and orange. Green (",
    project_bj1_p2_2: ") symbolizes vitality and growth, while orange (",
    project_bj1_p2_3:
      ") represents enthusiasm and energy. This combination not only aligns with the psychological characteristics of children but also adds a lively visual effect to the facility.",

    project_bj3_header: "Results",
    project_bj4_header: "Presentation",
    project_bj2_p1:
      "After finalizing the primary colors of green and orange, we further advanced the design of the equipment colors and layout within the facility. Our goal was to ensure that each element seamlessly integrates into the overall environment, enhancing the facility's visual appeal and functionality.",
    project_bj2_p2_title: "Color Coordination and Design",
    project_bj2_p2:
      "We meticulously planned the color scheme of the equipment according to the different functional areas and user groups. In the active zones for children, we used bright, high-contrast color combinations to spark their curiosity and sense of exploration. We paid special attention to the psychological impact of colors, choosing combinations that are child-friendly and stimulate creativity. This color strategy is not just about aesthetics but is also intended to boost children's engagement and enthusiasm.",
    project_bj2_p3_title: "Scene Rendering and Visual Simulation",
    project_bj2_p3:
      "Using 3D modeling and rendering software, we created detailed floor plans and scene simulations that replicate the actual space. This allows clients to fully experience the facility’s design without being physically present.",

    project_bj5_header: "LOGO",
    project_bj6_header: "Design",

    project_bj7_header: "More",
    project_bj8_header: "Content",
    carouselList1_title: "Showcase of the Actual Facility Design",

    project_summarize1: "Project",
    project_summarize2: "Summary",
    project_summarize_proj_bj_text:
      "Through the NINJA X brand design project, we witnessed the rise of a comprehensive, innovative, and effective children's skills development center. With a unique ninja-themed design, vibrant color palette, and carefully planned facility layout, we ensured the space’s visual appeal. Moving forward, we will continue to collaborate with NINJA X to develop new projects. Stay tuned for what’s next!",
  },

  // 博客首页
  blolist: {
    header_title1: "Explore",
    header_title2: "Insights, Industry Focus",
    gengduowenz: "Browse More Articles",
    header_tips:
      "Delve into the latest trends and innovations in the internet industry, helping businesses stay ahead of the curve and achieve digital transformation.",
  },
  // 博客 - 从线上出发：如何打造一个令人难忘的品牌形象
  blobrand: {
    header_title: "Starting Online: How to Create a Memorable Brand Identity",
    proj_bj_p1:
      "In this era of information overload, building a strong online brand identity is more important than ever. As more businesses embrace digital transformation, a brand is no longer just a name or a logo but a medium that conveys a company’s core values and personality. Online platforms have expanded the ways brands can be shaped, making the process more diverse and complex. This goes beyond first impressions—it influences long-term customer loyalty. Understanding the importance of branding is key to gaining a competitive edge.",
    proj_bj_p2_title:
      "First, we must understand that a brand is central to customer decision-making, simplifying their choices in both B2B and B2C markets.",
    proj_bj_p2:
      "When choosing products or services, customers often face numerous options. A strong, clear brand reduces their hesitation during evaluation. For instance, well-known companies like IBM or Accenture can charge premium prices because their brands convey market strength and reliability. When customers see these brands, they trust that the experience will align with the brand’s promise. A brand is not just a market marker; it is a symbol of trust.",
    proj_bj_p3:
      "In branding, businesses need to clearly communicate the value they offer. A brand conveys more than just a product's features—it shows how it meets customer needs. While many products may be similar, an attractive brand can help customers make quick decisions and even pay a premium, because the brand carries a promise of quality.",
    proj_bj_p4:
      "The power of a brand extends beyond short-term sales, influencing long-term growth. Through branding, businesses can increase market recognition and build lasting trust with customers. Once trust is established, customers are more likely to repurchase or recommend the brand to others. In fact, studies show that customers with positive brand experiences are more likely to advocate for the brand in different settings, creating a lasting word-of-mouth effect.",
    proj_bj_p5_title:
      "Additionally, a strong brand can significantly reduce customer acquisition costs as consumers become more selective about ads.",
    proj_bj_p6:
      "Many people research brand information before making a purchase, making a company’s website and third-party platform presence crucial.",
    proj_bj_p7:
      "A strong brand image can help a company stand out among competitors, reducing the need for extensive ad spending and marketing costs.",
    proj_bj_p8_title:
      "Another key aspect of branding is content marketing. In today's information-saturated world, brands need quality content to connect emotionally with customers.",
    proj_bj_p9:
      "Content is more than just ads or promotions; it’s a way for businesses to communicate their values, vision, and expertise.",
    proj_bj_p10:
      "For example, sharing industry insights, customer stories, or solutions can demonstrate expertise in a specific field. High-quality content builds credibility, attracts potential customers, and helps the brand establish a strong presence in the market.",
    proj_bj_p11:
      "At Blackwave, we understand the power of storytelling. By showcasing client success stories and our design process, we aren’t just demonstrating technical skills—we’re building trust with future clients. Every brand has a unique story, and we help them tell it in a way that resonates with new audiences.",
    proj_bj_p12:
      "Brands need to be seen and engaged with. Social media offers a great platform for direct interaction with customers, gathering feedback, and building engagement. Unlike traditional ads, social media interactions create a sense of closeness with the brand, boosting customer loyalty. In some cases, brands can encourage user-generated content (UGC) through social media campaigns, enhancing brand interaction. For example, some brands invite customers to share photos or experiences using specific hashtags, increasing brand visibility and engagement. This makes customers feel like part of the brand, further strengthening loyalty.",
    proj_bj_p13:
      "One of the biggest advantages of digital marketing is its trackability. By analyzing customer behavior data, businesses can better understand how their brand is performing in the market.",
    proj_bj_p14:
      "For instance, analyzing ad click-through rates or engagement with social media posts allows businesses to quickly adjust strategies and ensure effective brand communication. This data-driven approach to brand optimization helps businesses stay competitive in a fast-changing market.",
    proj_bj_p15:
      "In summary, a brand is not just a bridge between a company and its customers but the foundation for long-term market success. Whether through clear brand positioning, consistent visual design, or content marketing, the power of branding is everywhere. Mastering these elements helps businesses win customer trust and remain competitive for the future.",
  },
  // 博客 - 企业官网策划：如何通过精准内容策略推动ToB业务增长
  blotob: {
    header_title:
      "Corporate Website Planning: How to Drive B2B Growth with Precise Content Strategy",
    proj_bj_p1:
      "When creating a corporate website for B2B companies, strategic planning is a key step to success. Today, we’ll explore how effective planning can enhance a website's impact, transforming it from a simple showcase into a powerful marketing tool that attracts clients and boosts brand visibility and conversion rates.",
    proj_bj_p2_title: "1. Define Website Goals",
    proj_bj_p2:
      "Before planning a website, businesses need to clearly define its goals. A website can serve multiple roles, including:",
    proj_bj_p2_chil1:
      "Brand Presentation: Convey the company’s brand image and build trust with users.",
    proj_bj_p2_chil2:
      "Traffic Generation: Attract visitors through digital marketing methods (SEO, SEM, etc.) to increase website traffic.",
    proj_bj_p2_chil3:
      "Lead Conversion: Use rich content and clear calls to action to convert visitors into potential clients.",
    proj_bj_p2s:
      "Once the goals are set, website planning can focus on achieving them, ensuring that every page and feature is designed to support these objectives.",

    proj_bj_p3_title: "2. Choose the Right Website Type",
    proj_bj_p3:
      "Based on business positioning and goals, websites usually fall into the following types:",
    proj_bj_p3_chil1:
      "Business Card Website: Simple content that presents basic company information, such as an overview, contact details, and product/service listings. It’s mainly for establishing an online presence.",
    proj_bj_p3_chil2:
      "Brand Website: Focused on brand promotion, with high-quality design that showcases the company’s strengths and case studies to build brand recognition and trust.",
    proj_bj_p3_chil3:
      "Marketing Website: Not only showcases company information but also encourages visitor interaction through registrations, downloads, or forms, converting them into leads.",
    proj_bj_p3s:
      "Choosing the right type of website determines the overall content structure and functional design.",

    proj_bj_p4_title: "3. Design Website Architecture",
    proj_bj_p4:
      "The architecture of a website defines the user’s browsing path and experience. Typically, a B2B website structure includes key modules such as:",
    proj_bj_p4_chil1:
      "Homepage: Highlights the company's core values, product/service features, and quick links to important pages.",
    proj_bj_p4_chil2: `Product Introduction: Detailed descriptions of products or services, outlining their features, benefits, and use cases.
              Solutions: Tailored solutions that address client pain points, showcasing the company's expertise.`,
    proj_bj_p4_chil3:
      "Client Case Studies: Real-world examples of successful partnerships, enhancing trust.",
    proj_bj_p4_chil4:
      "About Us: Company history, leadership team, and accolades, building authority and credibility.",
    proj_bj_p4s: `When designing website architecture, focus on creating clear browsing paths so visitors can easily find the information they need. A well-organized structure improves user experience and reduces bounce rates.`,

    proj_bj_p5_title: "4. Domain and Server Selection",
    proj_bj_p5:
      "The choice of domain and server affects website performance and user experience. Select a domain that is short, memorable, and related to your brand. A reliable server ensures website stability, security, and the ability to handle growing traffic.",
    proj_bj_p5s:
      "Choosing a reliable server and a relevant domain ensures smooth website operation and scalability.",

    proj_bj_p6_title: "5. Website Content Strategy",
    proj_bj_p6:
      "Content is the core of a website, and a solid content strategy can engage visitors and convert them into leads. Key content areas include:",
    proj_bj_p6_chil1:
      " Product/Service Descriptions: Use the FABE method (Features, Advantages, Benefits, Evidence) to clearly describe product functions and their value to clients.",
    proj_bj_p6_chil2: `Solutions: Explain how the company addresses client challenges, highlighting expertise and tailored services.`,
    proj_bj_p6_chil3:
      "Client Case Studies: Showcase how the company has helped others succeed, including challenges, solutions, outcomes, and feedback to build credibility.",
    proj_bj_p6s: `Content should be rich and include clear CTAs (calls to action), such as “Register Now” or “Get a Free Demo,” guiding visitors to take the next step.`,

    proj_bj_p7_title: "6. User Experience and Design",
    proj_bj_p7:
      "A great website not only has rich content but also provides a user-friendly experience. Use effective visual design, simple navigation, and fast responsiveness to enhance user browsing. Responsive design is crucial to ensure the website displays well on different devices (PC, mobile, tablet).",
    proj_bj_p7_chil1: " Key design considerations include:",
    proj_bj_p7_chil2: `Visual Consistency: Ensure that colors, fonts, and icons align with the brand identity.`,
    proj_bj_p7_chil3:
      "Usability: Minimize clicks and ensure users can easily find what they’re looking for.",
    proj_bj_p7_chil4:
      " Loading Speed: Improve website speed through image compression and code optimization to reduce visitor drop-off.",
    proj_bj_p7_chil5:
      "Authority: Introduce the company’s history, leadership, and achievements to build credibility.",

    proj_bj_p8_title: "7. Data Tracking and Feedback Mechanism",
    proj_bj_p8:
      "Website planning should include data tracking to gather insights on user behavior. This data helps businesses understand user journeys, clicks, and time spent on the site, enabling further optimization of content and functionality. Continuous analysis allows companies to adjust their strategies to better meet user needs.",

    proj_bj_p9_title: "8. Ongoing Operation and Optimization",
    proj_bj_p9:
      "Website planning doesn’t end at launch; continuous optimization is needed. Regularly update content, refine page layouts, and adjust marketing strategies based on user feedback and data analysis to keep the site competitive. Regular SEO updates can also improve search engine rankings, attracting more organic traffic.",
    proj_bj_p10_title: "Conclusion",
    proj_bj_p10:
      "The strategies mentioned above are just the tip of the iceberg in website planning. Like navigating the ocean, it’s easy to see the waves, but the hidden depths often hold the real challenges.",
    proj_bj_p11:
      "A successful website is not just about design and functionality, but also about achieving business goals through content, structure, and user guidance. With strategic planning, thoughtful design, and continuous optimization, a corporate website can become a powerful tool for marketing and branding, helping businesses attract leads and increase conversion rates.",
  },
  // 博客 - 从用户体验到数据分析： 构建成功ToC网站的全攻略
  bloaddtob: {
    header_title:
      "User Experience to Data Analysis: A Guide to Building a Successful B2C Website",
    proj_bj_p1:
      "When creating a consumer-facing (B2C) website, it’s more than just a display platform—it's like a brand ambassador, always ready to assist users, encourage interaction, and drive action. So, what key steps should you consider to ensure your website is not only visually appealing but also effectively boosts brand awareness and conversion rates? Let’s dive into the essentials.",
    proj_bj_p2_title: "1. Define Core Goals",
    proj_bj_p2:
      "First, what is the role of your website? Is it meant to showcase products, strengthen your brand, or drive engagement? Think of it as a helpful salesperson—you want it to guide users to what they need and encourage them to take action. Generally, core goals can be divided into these three categories.",

    proj_bj_p3:
      "Product Sales: Streamline the purchasing process for quick transactions. To boost sales, simplify the shopping process, ensuring users can complete purchases quickly. Design intuitive product categories, provide clear product detail pages, simplify the cart and checkout process, and minimize steps and time required. Support various payment methods and quick checkout options like 'One-Click Buy' for a seamless transaction experience.",
    proj_bj_p4:
      "Brand Promotion: Build brand recognition and encourage consumer engagement. Effective brand promotion highlights your values and story through website design. Share high-quality content like brand stories, testimonials, and media features to strengthen emotional connections with consumers. The website’s visual design should align with the brand identity to help establish recognition and trust.",
    proj_bj_p5:
      "User Engagement: Encourage users to register, share, and interact, increasing their loyalty. User engagement means guiding users to interact more with your website. Use membership registrations, loyalty programs, and sharing options to foster active participation. User-generated content (like reviews and photos), social media integration, and promotional events also keep users engaged and active on the site.",

    proj_bj_p6_title: "2. Choose the Right Website Type",
    proj_bj_p6:
      "Just as different store styles attract different customers, your website should be tailored to your business type. Do you need an e-commerce platform for easy purchasing or a branding website to tell your story?",
    proj_bj_p7:
      "Showcase Website: Focuses on brand image and business information, using elegant design and high-quality visuals to attract users. Ideal for creative agencies, artists, or companies showcasing professional services, helping build brand trust and emotional connection.",
    proj_bj_p8:
      "Booking Website: Designed for online booking and scheduling services, such as gyms, clinics, and training centers. Features like real-time calendars, online payments, and automated reminders make the booking process smooth and reduce manual workload.",
    proj_bj_p9:
      "E-commerce Website: Focused on selling products or services, featuring shopping carts, product displays, online payments, and user accounts. Suitable for retailers or brand stores, it enhances the user shopping experience and conversion rates with a streamlined purchase process and multiple payment options.",

    proj_bj_p10_title: "3. User Experience Is Key",
    proj_bj_p10:
      "No one likes shopping in a cluttered store, and the same goes for websites. Use smooth navigation and clear design to guide users through products and information. User experience design (UX) makes your website like a neat, welcoming store, encouraging users to stay and shop. A clear understanding of your product positioning and a deep analysis of user needs are critical.",
    proj_bj_p11_title: "4. Choose the Right Domain and Server",
    proj_bj_p11:
      "Just like a store sign, your domain should be simple and memorable, while the server is the logistics behind the scenes, ensuring smooth operation. At Blackwave, our server setup ensures your 'store' can handle peak traffic seamlessly.",
    proj_bj_p12_title: "5. Content Planning: Attract and Engage Users",
    proj_bj_p12:
      "Every page is a conversation with visitors. Use high-quality content (product descriptions, user reviews, promotions) combined with SEO to make sure both users and search engines can easily find you. Consistent content output boosts your website's authority and user retention, so having a solid strategy is key.",
    proj_bj_p13_title: "6. Effective CTAs to Drive Action",
    proj_bj_p13:
      "Every page should have a clear call to action (CTA). Buttons like 'Buy Now' or 'Add to Cart' act as a bridge between you and users. Eye-catching, persuasive designs can significantly boost click-through rates. A good website predicts user behavior, ensuring a smooth experience that leads to conversion.",

    proj_bj_p14_title: "7. Data Tracking and Feedback",
    proj_bj_p14:
      "Monitor user behavior on your website to identify which pages and buttons get the most clicks or where users drop off. Use data insights to enhance user experience and conversion rates. Regularly analyze this data to refine strategies and capture every potential customer.",
    proj_bj_p15_title: "8. Ongoing Operation and Optimization",
    proj_bj_p15:
      "Think of your website as a store—it needs regular updates, new arrivals, and sometimes a fresh look. Continuous content updates and SEO optimization keep users interested and coming back.",
    proj_bj_p16_title: "Conclusion",
    proj_bj_p16:
      "These are the essentials for building a successful B2C website, from defining your goals to creating a framework and refining the details through regular updates. Like navigating the ocean, it's easy to see the waves on the surface, but the hidden depths often hold the real challenges.",

    proj_bj_p17:
      "Successful website planning goes beyond design and functionality; it's about using content, structure, and user guidance to achieve business goals. With strategic planning, thoughtful design, and ongoing optimization, your website can provide a great visual and user experience, driving growth and success.",
  },
  //联系我们
  contact: {
    header_title1: "Bring a",
    header_title2: " Custom Solution to Your Project?",
    header_tips:
      "Thank you for submitting your inquiry on our website! Our business manager will reach out to you within 24 hours to schedule a meeting and discuss your project needs and business goals in detail. Following that, our solution specialist will provide you with a preliminary customized plan, including the estimated timeline and cost information.",

    form_header_box1: "Let's build the ",
    form_header_box2: "blueprint together",

    name_input: "Your Name",
    name_inputs: "Contact Us",
    email_input: "Your Email",
    type_input: "Type of Custom Product",
    type_input_1: "e.g., Product Development",
    type_input_2: "Custom Software",
    type_input_3: "Custom Website Development",
    type_input_4: "Brand Design",
    type_input_5: "One-Stop Solution",
    messages_input: "Your Project Requirements",
    SubmitForm: "Contact Now",
  },
  customized: {
    title: "Need a Custom Solution for",
    title1: "Your Project?",
    tipe: "Creating  Solutions That Fit *You* for Businesses Worldwide.",
    tipe_ri: "Let’s Take the Next Step Together.",
    btn: "Contact Us",
  },
};
