<template>
  <div @scroll="getScrollTop" class="max_hegth">
    <GlobalHeader @goAssignBlock="goAssignBlock" />
    <section class="hero-5-bg position-relative">
      <router-view ref="view" :key="key" />
      <!-- <div class="scrollBox" v-show="isShow">
        <div class="scrollBox_box">
          <transition name="welcome1">
            <img
              src="../assets/images/code.png"
              v-show="yc"
              class="code_logo welcome1"
              alt=""
            />
          </transition>

          <img
            src="../assets/images/zx.png"
            @mouseover="mouseover"
            @mouseleave="mouseleave"
            class="zx_logo"
            alt=""
          />
          <img
            src="../assets/images/xtop.png"
            @click="upTop"
            class="xtop_logo"
            alt=""
          />
        </div>
      </div> -->
    </section>
    <GlobalFooter @goAssignBlock="goAssignBlock" />
  </div>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader";
import GlobalFooter from "@/components/GlobalFooter";

export default {
  name: "BasicLayout",
  components: {
    GlobalHeader,
    GlobalFooter,
  },
  data() {
    return {
      scrollTop: 0,
      isShow: false,
      yc: false,
    };
  },
  computed: {
    key() {
      return this.$route.path + Date.now();
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.getScrollTop, true);
  },
  methods: {
    // 监听滚动条
    getScrollTop() {
      let that = this; // 防止this指向问题
      // 设备一屏的高度
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;

      // 判断距离顶部多少显示回到顶部图标
      if (scrollTop > clientHeight) {
        that.isShow = true;
      } else {
        that.isShow = false;
      }
    },
    mouseover() {
      // 鼠标移入事件mouseover
      this.yc = true;
    },
    mouseleave() {
      // 鼠标移出事件mouseleave
      this.yc = false;
    },
    upTop() {
      document.documentElement.scrollTop = 0;
    },
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight; //浏览器窗口高度

      // let h = this.$refs.view.$children[0].$refs[el].offsetHeight; //模块内容高度
      let t = this.$refs.view.$children[0].$refs[el].offsetTop; //模块相对于内容顶部的距离

      let top = t - (windowH - t) / 2; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条距离顶部高度
      let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId;
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向下滚动
          currentTop += speed;
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向上滚动
          currentTop -= speed;
        }
      }
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/css/pc.css";
/* 返回顶部样式 */
.scrollBox {
  position: fixed;
  right: 0.9375rem;
  bottom: 8.625rem;
  font-size: 0.6875rem;
  z-index: 99;
  .scrollBox_box {
    display: flex;
    flex-direction: column;
    position: relative;
    .code_logo {
      position: absolute;
      width: 6.6563rem;
      left: -7.1875rem;
      top: -3.75rem;
      background: #fff;
      border-radius: 0.375rem;
    }
    .zx_logo {
      width: 2.0625rem;
    }
    .xtop_logo {
      width: 2.0625rem;
    }
  }
}
.welcome1-enter-active,
.welcome1-leave-active {
  transition: opacity 0.5s;
}

.welcome1-enter,
.welcome1-leave-to {
  opacity: 0;
}
.max_hegth {
  // min-height: 100vh;
  background: #15171d;
}
</style>
