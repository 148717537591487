<template>
  <nav
    id="navbarHeader"
    :class="`${
      btnFlag
        ? 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky'
        : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center '
    } ${topShow ? ' head-move' : ''}`"
  >
    <div class="container">
      <div class="flex-wb header_top_a">
        <div
          class="logo mr-3 d-flex justify-content-center align-items-center"
          style="margin-right: 5.1563rem"
        >
          <img
            src="../assets/images/new_min_logo.png"
            class="header_logo"
            alt="..."
            @click="routerBtn('/')"
          />
        </div>

        <div
          class="navbar-toggler"
          :class="show ? 'on' : ''"
          @click="show = !show"
          type="button"
          data-toggle="collapse"
          data-target="#navbarHeader"
          aria-controls="navbarHeader"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <!-- <img
            v-show="!btnFlag"
            src="@/assets/images/daohanlan.svg"
            style="width: 1rem"
            alt=""
          />
          <img
            v-show="btnFlag"
            src="@/assets/images/daohanlans.svg"
            style="width: 1rem"
            alt=""
          /> -->
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>

      <div
        class="collapse navbar-collapse"
        :class="show ? 'show' : ''"
        id="navbarCollapse"
      >
        <ul class="navbar-nav navbar-center flex-wb" id="mySidenav">
          <li
            class="nav_li_item"
            :class="btnFlag ? 'nav-items' : ''"
            @click="routerBtn('/')"
          >
            <div class="nav-item">
              <span class="T18px_Regular">{{ $t("globalHeader.home") }}</span>
              <span class="T18px_Regular color1D73FF">{{
                $t("globalHeader.home")
              }}</span>
            </div>
          </li>
          <li
            class="nav_li_item h5_daohang"
            :class="btnFlag ? 'nav-items' : ''"
          >
            <div class="nav-item flex-wb" @click="routerBtn('/servicehome', 2)">
              <div>
                <span class="T18px_Regular">{{
                  $t("globalHeader.service")
                }}</span>
                <span class="T18px_Regular color1D73FF">{{
                  $t("globalHeader.service")
                }}</span>
              </div>
              <img
                src="@/assets/images/arr_icon.png"
                class="arr_icon_img"
                alt=""
                v-show="!childShow"
              />
              <img
                src="@/assets/images/arr_icons.png"
                class="arr_icon_img"
                alt=""
                v-show="childShow"
              />
            </div>

            <ul class="child_box" :class="childShow ? 'act' : ''" type="disc">
              <li @click="routerBtn('/servicehome')">
                {{ $t("globalHeader.servicechil1") }}
              </li>
              <li @click="routerBtn('/servicehome')">
                {{ $t("globalHeader.servicechil2") }}
              </li>
            </ul>
          </li>

          <li
            class="nav_li_item pc_daohang"
            :class="btnFlag ? 'nav-items' : ''"
          >
            <div class="nav-item flex-wb" @click="routerBtn('/servicehome')">
              <div>
                <span class="T18px_Regular">{{
                  $t("globalHeader.service")
                }}</span>
                <span class="T18px_Regular color1D73FF">{{
                  $t("globalHeader.service")
                }}</span>
              </div>
            </div>
          </li>
          <li
            @click="routerBtn('/case')"
            class="nav_li_item"
            :class="btnFlag ? 'nav-items' : ''"
          >
            <div class="nav-item">
              <span class="T18px_Regular">{{ $t("globalHeader.case") }}</span>
              <span class="T18px_Regular color1D73FF">{{
                $t("globalHeader.case")
              }}</span>
            </div>
          </li>
          <li
            class="nav_li_item"
            @click="routerBtn('/blolist')"
            :class="btnFlag ? 'nav-items' : ''"
          >
            <div class="nav-item">
              <span class="T18px_Regular">{{ $t("globalHeader.blog") }}</span>
              <span class="T18px_Regular color1D73FF">{{
                $t("globalHeader.blog")
              }}</span>
            </div>
          </li>
          <li
            @click="routerBtn('/contact')"
            class="nav_li_item"
            :class="btnFlag ? 'nav-items' : ''"
          >
            <div class="nav-item">
              <span class="T18px_Regular">{{
                $t("globalHeader.contact")
              }}</span>
              <span class="T18px_Regular color1D73FF">{{
                $t("globalHeader.contact")
              }}</span>
            </div>
          </li>
          <li
            @click="switchLanguage($t('globalHeader.language'))"
            class="nav_li_item"
            :class="btnFlag ? 'nav-items' : ''"
          >
            <div class="nav-item">
              <span class="T18px_Regular">
                {{ $t("globalHeader.language") }}
              </span>
              <span class="T18px_Regular color1D73FF">
                {{ $t("globalHeader.language") }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import $ from 'jquery'
export default {
  name: "GlobalHeader",
  data() {
    return {
      btnFlag: false,

      show: false,

      topShow: false,

      childShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
    window.addEventListener("wheel", this.handleWheel);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
    window.addEventListener("wheel", this.handleWheel);
  },
  methods: {
    /**
     * 切换中英文方法
     * @param {*} type 当前中英文状态
     */
    switchLanguage(type) {
      console.log(type);
      if (type == "English") {
        localStorage.setItem("locale", "en");
      } else {
        localStorage.setItem("locale", "zh");
      }
      
      this.$router.go();
    },
    /**
     * 监听页面是向上滑动还是向下滑动
     */
    handleWheel(event) {
      const deltaY = event.deltaY;
      if (deltaY < 0) {
        this.topShow = false;
      } else if (deltaY > 0) {
        this.topShow = true;
      }
    },
    /**
     * 滚动到指定位置
     */
    goAssignBlock(el, speed, url, query) {
      let path = this.$route.path;

      if (path.indexOf("url") == -1) {
        this.$router.push({ path: url, query: query });
      }

      this.$emit("goAssignBlock", el, speed, url);
    },
    scrollToTop(e) {
      this.show = false;
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    routerBtn(url, type) {
      if (type == 2) {
        this.childShow = !this.childShow;
        return;
      }
      this.$router.push(url);
      this.show = false;
      this.childShow = false;
    },
  },
};
</script>

<style scoped lang="less">
.header_logo {
  width: 10.875rem;
  height: 1.375rem;
}

::v-deep .nav-item .dropdown-menu {
  background: rgba(41, 35, 35, 0.8);
}

::v-deep .nav-items .dropdown-menu {
  // background: #fff;
}

.nav_item_no_item {
  display: flex;
  align-items: flex-end;

  .text_btn {
    display: inline-block;
    text-align: center;
    width: 3.5rem;
    height: 1.3438rem;
    line-height: 1.3438rem;
    border-radius: 1.6563rem;
    border: 0.0313rem solid #ffffff;
    overflow: hidden;
  }
}

.navbar-custom .navbar-nav {
  width: 18.375rem;

  .nav-item {
    height: 0.825rem;
    color: #ffffff;
    display: inline-block;
    transition: all 0.4s;
    line-height: 0.825rem;
    overflow: hidden;

    // flex-wrap: nowrap;
    &:hover {
      span {
        transition: all 0.6s;
        transform: translateY(-100%);
      }
    }

    span {
      display: block;
      pointer-events: none;
    }
  }
}

.before_line::before {
  content: "";
  position: absolute;
  width: 2.125rem;
  height: 0px;
  border: 0.0313rem solid #ffffff;
  bottom: -0.5625rem;
  right: 0;
  transition: all 1s;
}

.before_line:hover:before {
  transition: all 1s;
  background: #a6b565;
  border: 0.0313rem solid #a6b565;
}

.navbar-center,
.container {
  display: flex;
  align-items: center;
}

.navbar > .container {
  display: flex;
  align-items: center;
}

.show {
  flex-basis: auto;

  .navbar-nav {
    margin: 0 !important;
    flex-direction: column;

    .nav-item {
      height: 1.5rem;

      span {
        // display: inline-block;
        height: 1.5rem;
      }
    }
    .nav_item_no_item {
      .text_btn {
        height: 1.5rem;
        padding: 0;
        margin: 0;
        border: none;
        line-height: normal;
      }
    }
  }
}
.child_box {
  display: none;
}

.h5_daohang {
  display: none;
}
@media (min-width: 200px) and (max-width: 768px) {
  .h5_daohang {
    display: block;
  }
  .pc_daohang {
    display: none;
  }
  .logo {
    margin-left: 0 !important;
  }
  .child_box {
    display: block;
  }
  .navbar-custom {
    height: 3.2188rem;
    background: #15171d;
    padding: 0;
    .header_top_a {
      position: absolute;
      left: 0;
      width: 100%;
      padding: 0 1.4813rem !important;
      height: 3.2188rem;
      z-index: 9;
      background: #15171d;
    }
  }
  .navbar-custom .container {
    // padding: 0;
    // padding-right: calc(var(--bs-gutter-x) * 0.5);
    // padding-left: calc(var(--bs-gutter-x) * 0.5);
    position: relative;
  }

  .navbar-custom > .container {
    width: 100% !important;
  }
  .collapse:not(.show) {
    display: block;
    top: -102.4096rem !important;
    transition: all 0.5s;
  }

  #navbarCollapse {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    margin-top: 3.1188rem;
    background: #15171d;
    padding: 0;
    transition: all 0.5s;
    z-index: 2;
    .navbar-nav {
      width: 100%;
      align-items: flex-start;

      .nav_li_item {
        width: 100%;
        padding: 0 1.25rem;
        border-bottom: 0.0602rem solid #e7e7e7;
        padding-bottom: 1.0663rem;
        padding-top: 1.2096rem;
        position: relative;
        .child_box {
          color: #ffffff;
          font-size: 0.8375rem;
          font-weight: 400;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          height: 0;
          overflow: hidden;
          transition: all 0.3s;
          li {
            margin-bottom: 0.9036rem;
          }
          &.act {
            margin-top: 1.506rem;
            height: 3.6145rem;
          }
        }
      }
      .nav-item {
        width: 100%;
        height: 1.6rem;
        line-height: 1.6rem;
        .arr_icon_img {
          width: 0.8024rem;
          position: absolute;
          right: 1.25rem;
          top: 2rem;
        }
      }
      .T18px_Regular {
        font-size: 1rem;
      }
    }
  }

  .navbar-toggler {
    width: 1.4253rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    span {
      width: 100%;
      height: 0.1205rem;
      background: #fff;
      margin: 0.1807rem 0;
      border-radius: 0.1205rem;
      transition: all 0.3s;
    }
    &.on {
      span:nth-child(1) {
        transform: rotateZ(45deg);
        transform-origin: left center;
      }
      span:nth-child(2) {
        opacity: 0;
      }
      span:nth-child(3) {
        transform-origin: left center;
        transform: rotateZ(-45deg);
      }
    }
  }
}
</style>
