// 注意：一定是 exports，不是 export，否则会报错，报错信息是下列的中的内容不是 string
module.exports = {
  mesg:"恭喜你提交成功，我们将在24小时之内联系你",
  //导航栏
  globalHeader: {
    home: "首页",
    service: "服务",
    servicechil1: "定制开发/设计",
    servicechil2: "一站式搭建",
    case: "案例",
    blog: "博客",
    contact: "联系我们",
    language: "English",
  },
  // 首页
  home: {
    headerTitle: "以科技为帆，乘风破浪",
    projectListInfo: "查看项目",
    projectListInfoTitle1: "魔训",
    projectListInfoTips1:
      "一款运动健身平台，集预约私教与跟练游戏化设计于一体，让你的训练更加个性化、有趣且充满动力。无论是提升体能、挑战自我，还是寻找专属指导。",
    projectListInfoTitle2: "忍者X 学院",
    projectListInfoTips2:
      "先锋训练中心：激发儿童潜力，培养未来领袖。通过创新的忍者训练，全面发展每一个孩子，激励与赋能，让他们勇敢迈向未来。",
    projectListInfoTitle3: "山海传奇",
    projectListInfoTips3:
      "平台鼓励儿童青少年们通过接触大自然，奔跑，跳跃，玩耍，帮助其他人，找回丢失的童真时代。",
    scalingText:
      "我们提供定制化软件开发、独立网站建设和品牌设计服务。通过制定切实可行的商业策略，结合互联网技术和创新设计，致力于为客户和品牌打造独特且具备竞争力的数字化解决方案。",
    flowTitle1_a: "用户",
    flowTitle1_b: "体验",
    flowTitle2_a: "视觉",
    flowTitle2_b: "设计",
    flowTitle3_a: "内容",
    flowTitle3_b: "设表",
    flowTitle4_a: "测试",
    flowTitle4_b: "开发",
    flowTitle1_text:
      "我们不仅注重与您的交流体验，更重要的是您所面向的用户的体验，您的目标将是我们的目标。我们有最专业的团队专门帮助您探讨与分析，并在其过程中严格遵守定制流程，从而确保达到理想的用户体验和项目目标，我们将投入100",
    flowTitle1_chile1: "客户交流",
    flowTitle1_chile2: "用户调研",
    flowTitle1_chile3: "竞品分析",
    flowTitle1_chile4: "原型图",
    flowTitle2_text:
      "视觉作为第一冲击力的无声力量，是在数字化产品开发中重要的一部分，它不仅塑造了用户对品牌的第一印象，还直接影响着用户的使用体验。吸引更多用户停留，从而提升更高转化率是我们共同的方向，通过精心设计的视觉元素，能够有效传达品牌形象和价值观，我们所提供的视觉设计，就是您企业形象的缩影！",
    flowTitle2_chile1: "视觉风格",
    flowTitle2_chile2: "动效设计",
    flowTitle2_chile3: "UI 设计",
    flowTitle3_text:
      "信息爆炸，内容为王的时代。我们将定制清晰，有吸引力的内容帮助用户快速获取想得到的信息，增强使用体验，同时采用适合企业的富内容表现形式，拒绝同质化。通过精准的内容策略，保证信息传递的准确性和一致性，目标与您一起打造独一无二的专属企业内容",
    flowTitle3_chile1: "文案编写",
    flowTitle3_chile2: "排版布局",
    flowTitle3_chile3: "创意设计",
    flowTitle4_text:
      "测试开发直接关系到功能稳定性和用户体验。通过前端和后端的紧密协作，将视觉设计与功能需求转化为高效、可靠的代码。测试环节是保障产品质量的关键，通过全面性测试，及时发现并解决潜在的问题。当然，我们将继续提供两年的售后支持，维护您产品的万无一失",
    flowTitle4_chile1: "前端开发",
    flowTitle4_chile2: "后端开发",
    flowTitle4_chile3: "产品测试",
    flowTitle4_chile4: "支持与维护",
    right_text: `我<br />们<br />的<br /> 服<br />务`,

    blog_left_tips: `新<br />闻<br />博<br />客`,
    blog_title1: "探索",
    blog_title2: "观点，聚焦行业",
    blog_list1_time: "2024年8月18日",
    blog_list1_title: "从线上出发：如何打造一个令人难忘的品牌形象",
    blog_list2_time: "2024年9月10日",
    blog_list2_title: "企业官网策划：如何通过精准内容策略推动ToB业务增长",
    blog_list3_time: "2024年10月1日",
    blog_list3_title: "从用户体验到数据分析：构建成功ToC网站的全攻略",
    gengduo: "阅读更多",
    gengduos: "更多文章",
  },
  // 服务
  servicehome: {
    header_title1: "用户为",
    header_title2: "中心，",
    header_title3: "品牌为",
    header_title4: "核心，",
    header_title5: "策略为",
    header_title6: "导向",
    header_title7: "定制化",
    header_title8: "产品服务",
    software_title1: "软件定制",
    software_title2: "开发",
    software_chil1: "APP开发",
    software_chil2: "微信小程序开发",
    software_chil3: "办公室管理系统定制",
    software_chil4: "软件定制开发",
    software_chil5: "跨平台兼容",
    software_chil6: "第三方兼容",

    website_title1: "网站定制",
    website_title2: "开发",
    website_chil1: "APP开发",
    website_chil2: "微信小程序开发",
    website_chil3: "办公室管理系统定制",
    website_chil4: "软件定制开发",
    website_chil5: "跨平台兼容",
    website_chil6: "第三方兼容",

    brand_title1: "品牌",
    brand_title2: "设计",
    brand_chil1: "品牌定位",
    brand_chil2: "品牌形象设计",
    brand_chil3: "品牌logo/标语",
    brand_chil4: "视觉识别系统VI",
    brand_chil5: "品牌融入设计",
    brand_chil6: "品牌传播策略",

    touch_title1: "一站式",
    touch_title2: "搭建",
    touch_chil1: "网站开发",
    touch_chil2: "软件/小程序",
    touch_chil3: "品牌宣传",

    ljie: "30秒了解服务流程",
  },
  // 服务-软件开发
  software: {
    title1: "开发",
    title2: "软件",
    header_title_tips:
      "我们可以一站式帮助您的品牌从0-1的搭建。确保软件顺利从构想到落地，完成从零到初步成功的过渡，我们将投入100%专业能力和水平与您共同打造最坚实的地基",
    stepArrList1_name: "客户",
    stepArrList1_names: "咨询",
    stepArrList1_text:
      "感谢您在我们网站上提交咨询！我们的商务经理将在24小时内与您联系，并安排一次会议，详细讨论您的项目需求和业务目标。之后，我们的方案专家会为您提供一份初步的定制化解决方案，并涵盖相关时间和费用信息。",
    stepArrList2_name: "共创",
    stepArrList2_names: "阶段",
    stepArrList2_text:
      "项目正式启动，主要任务是收集您的需求。我们将共同了解并讨论您的建站目标、所需功能，以及内容传达等。我们将由产品经理负责网站架构设计，确保从早期规划阶段开始，就与您的业务目标紧密结合",
    stepArrList3_name: "定制化",
    stepArrList3_names: "设计",
    stepArrList3_text:
      "我们会准备一份详细的开发需求文档，涵盖设计师和开发工程师需要了解的所有信息，产品经理和UI/UX设计师将协同合作，完成定制化网站的原型设计和界面设计。在您确认无误后，我们将进入程序开发阶段。",
    stepArrList4_name: "程序",
    stepArrList4_names: "开发",
    stepArrList4_text:
      "前端开发工程师负责将设计稿转化为实际的网站界面和交互效果，同时确保网站在各种屏幕尺寸上的兼容性。后端开发工程师则根据需求和设计规划构建网站的后端逻辑和功能，实现数据处理和业务逻辑的支持。",
    stepArrList5_name: "产品",
    stepArrList5_names: "测试",
    stepArrList5_text:
      "开发人员完成网站开发后，测试工程师会对产品进行全面的测试，包括功能测试、兼容性测试和性能测试等环节。根据测试结果，进行优化和修复，以确保网站的稳定性并提供良好的用户体验。",
    stepArrList6_name: "部署",
    stepArrList6_names: "上线",
    stepArrList6_text:
      "最终项目会与您一起验收，验收完成后开发人员负责将您的程序部署到服务器，确保程序能够顺利上线并访问。我们将处理域名配置、服务器设置等相关事宜。",
    stepArrList7_name: "支持",
    stepArrList7_names: "与维护",
    stepArrList7_text:
      "项目结束后，我们还会将所有项目资料和源代码完整交付给您。此外为保证后续的服务质量跟项目跟进，我们无条件提供两年的技术问题修改与品牌策略咨询。",
  },
  // 服务-网站软件
  website: {
    title1: "网站",
    title2: "软件",
    header_title_tips:
      "我们可以一站式帮助您的品牌从0-1的搭建。确保网站顺利从构想到落地，完成从零到初步成功的过渡，我们将投入100%专业能力和水平与您共同打造最坚实的地基",
    stepArrList1_name: "客户",
    stepArrList1_names: "咨询",
    stepArrList1_text:
      "感谢您在我们网站上提交咨询！我们的商务经理将在24小时内与您联系，并安排一次会议，详细讨论您的项目需求和业务目标。之后，我们的方案专家会为您提供一份初步的定制化解决方案，并涵盖相关时间和费用信息。",
    stepArrList2_name: "调研",
    stepArrList2_names: "与方案",
    stepArrList2_text:
      "项目正式启动，主要任务是收集您的需求。我们将共同了解并讨论您的建站目标、所需功能，以及内容传达等。我们将由产品经理负责网站架构设计，确保从早期规划阶段开始，就与您的业务目标紧密结合",
    stepArrList3_name: "定制化",
    stepArrList3_names: "设计",
    stepArrList3_text:
      "我们会准备一份详细的开发需求文档，涵盖设计师和开发工程师需要了解的所有信息，产品经理和UI/UX设计师将协同合作，完成定制化网站的原型设计和界面设计。在您确认无误后，我们将进入网站开发阶段。",
    stepArrList4_name: "网站",
    stepArrList4_names: "开发",
    stepArrList4_text:
      "前端开发工程师负责将设计稿转化为实际的网站界面和交互效果，同时确保网站在各种屏幕尺寸上的兼容性。后端开发工程师则根据需求和设计规划构建网站的后端逻辑和功能，实现数据处理和业务逻辑的支持。",
    stepArrList5_name: "产品",
    stepArrList5_names: "测试",
    stepArrList5_text:
      "开发人员完成网站开发后，测试工程师会对产品进行全面的测试，包括功能测试、兼容性测试和性能测试等环节。根据测试结果，进行优化和修复，以确保网站的稳定性并提供良好的用户体验。",
    stepArrList6_name: "部署",
    stepArrList6_names: "上线",
    stepArrList6_text:
      "最终项目会与您一起验收，验收完成后开发人员负责将您的程序部署到服务器，确保程序能够顺利上线并访问。我们将处理域名配置、服务器设置等相关事宜。",
    stepArrList7_name: "支持",
    stepArrList7_names: "与维护",
    stepArrList7_text:
      "项目结束后，我们还会将所有项目资料和源代码完整交付给您。此外为保证后续的服务质量跟项目跟进，我们无条件提供两年的技术问题修改与品牌策略咨询。",
  },
  //服务-品牌设计
  brand: {
    title1: "品牌",
    title2: "设计",
    header_title_tips:
      " 我们能够助力您的品牌从0-100的长远成功。从初步发展到全面扩展的各个阶段，确保您能够在市场中实现持续增长和长远成功。我们不仅关注品牌的起步，更致力于帮助您在品牌成熟、扩展和优化过程中取得稳定和显著的进展，确保品牌在市场中的稳固地位和长久影响力。",
    stepArrList1_name: "客户",
    stepArrList1_names: "咨询",
    stepArrList1_text:
      "感谢您在我们网站上提交咨询！我们的商务经理将在24小时内与您联系，并安排一次会议，详细讨论您的项目需求和业务目标。之后，我们的方案专家会为您提供一份初步的定制化解决方案，并涵盖相关时间和费用信息。",
    stepArrList2_name: "调研",
    stepArrList2_names: "与方案",
    stepArrList2_text:
      "我们通过深入的研究来支持您的新品牌战略。借助内部评估、客户和竞争对手分析，以及利益相关者的调研，我们汇集所有必要信息，为塑造品牌新形象打下坚实基础。",
    stepArrList3_name: "色调与",
    stepArrList3_names: "情绪",
    stepArrList3_text:
      "深入探索品牌设计选项，精心构建色彩方案与标志等核心元素，将情绪与视觉色调紧密结合。通过这种设计，我们确保每一个细节都能传达品牌的情感内涵，增强品牌的视觉吸引力和记忆点。",
    stepArrList4_name: "品牌",
    stepArrList4_names: "交付",
    stepArrList4_text:
      "在以上协议确认后，我们将交付最终成果并为您的新品牌发布做好充分准备。最终交付内容将包含必要的资源，包括适用于印刷和数字平台的素材、品牌使用指南和详细规范，此外还将提供常见技术问题的解答，确保品牌发布顺利无忧。",
    stepArrList5_name: "支持与",
    stepArrList5_names: "维护",
    stepArrList5_text:
      "项目结束后，我们还会将所有项目资料交付给您。此外为保证后续的服务质量跟项目跟进，我们无条件提供两年品牌策略咨询。",
  },
  // 案例
  case: {
    title1: "破浪前行，",
    title2: "创新驱动的数字未来",
    rigth_text_box:
      "在快速变化的科技黑浪中，我们专注于通过创新的数字化开发为用户创造卓越体验。借助全球视野与技术专长，我们不仅驾驭最新的科技趋势，推动界限，打造技术解决方案，助力您品牌在激烈竞争中脱颖而出，实现持续成功。",
    menu_btn1: "定制开发/设计",
    menu_btn2: "一站式搭建",
    projecList1_name: "魔训",
    projecList1_tips: "让训练像魔法一样快乐",
    projecList1_child1: "软件定制",
    projecList1_child2: "移动开发",
    projecList2_name: "山海传奇",
    projecList2_tips: "探索自然力量，激发运动激情",
    projecList2_child1: "小程序定制",
    projecList2_child2: "移动开发",
    projecList3_name: "学院管理",
    projecList3_tips: "智慧学院, 高效便捷的管理系统",
    projecList3_child1: "程序定制",
    projecList3_child2: "移动开发",
    projecList4_name: "忍者X 学院",
    projecList4_tips: "挑战极限的障碍运动，为青少年打造专属训练场",
    projecList4_child1: "品牌定制",
    projecList4_child2: "VI视觉系统",
  },
  // 案例 -魔训
  moXun: {
    headerTitle: "魔训",
    jb_title1: "健身私教运动行业",
    jb_title2: "程序开发",
    left_box_tips:
      "魔训是一家专注于私教健身的运动公司，冉明-魔训的创始人找到我们想做出一款创新的线上运动平台，将用户、私教和场馆，三者串联在一起，利用游戏化训练、个性化推荐和互动社区，将健身运动转变为一种有趣，易坚持，快乐的体验，从而有效提升用户的参与度、坚持性和整体满意度。",
    headerList1_title: "服务内容",
    headerList1_chlie1: "小程序APP开发",
    headerList1_chlie2: "用户体验",
    headerList1_chlie3: "软件维护",
    headerList1_chlie4: "技术决策",

    headerList2_title: "运用平台",
    headerList2_chlie1: "安卓平台",
    headerList2_chlie2: "IOS平台",
    headerList2_chlie3: "Uniapp",

    headerList3_title: "采用技术",
    headerList3_chlie1: "高德地图API",
    headerList3_chlie2: "电商支付",
    headerList3_chlie3: "一键登录",

    project_bj_title1: "项目",
    project_bj_title2: "背景",
    project_bj__p1: "打破传统，引领未来——魔训APP的初衷",
    project_bj__p1s:
      "在当前预发竞争与饱和的健身行业，传统的健身房模式——囤课办卡——逐渐让消费者感到疲惫。人们渴望一种更加个性化、灵活和愉悦的健身体验，这正是魔训（MOTION）想致力于提供的。魔训希望这款软件的出现，就像大雾中的一盏明灯，犹如先锋者般探索下一个健身行业纪元，重新定义了人们对健身的期待。",
    project_bj__p2: "创新的理念，魔训的诞生",
    project_bj__p2s:
      "冉明-魔训的创始人兼前威尔士健身房西南地区总经理，与Blacewave团队不断碰撞，头脑风暴，大量用户的测试。更新并迭代了最新一版的魔训- 一个将快乐，专业，便捷，自律融合的健身APP。",

    challenge_title1: "分析",
    challenge_title2: "市场",
    challenge_p1:
      "我们分析了以下六点关于目前健身行业中用户面临的普遍问题，涵盖了从销售到体验、经济到心理的多方面挑战。解决这些痛点需要从产品设计、服务提供、用户体验等多维度进行创新和改进，以满足现代用户对个性化、便捷性和性价比的需求。",
    challenge_p1_chil1: "1.0 私教重视销售",
    challenge_p1_chil2: "2.0 时间地点限制",
    challenge_p1_chil3: "3.0 难坚持，训练枯燥",
    challenge_p1_chil4: "4.0 缺少个性化课程",
    challenge_p1_chil5: "5.0 私教课程高昂且需囤课",
    challenge_p1_chil6: "6.0 缺少激励目标",

    derive_header1: "衍生",
    derive_header2: "方案",

    deriveList1_title: "1.0 大数据分析|私教社群",
    deriveList1_infoText:
      "大数据分析功能，根据数据标签和用户填写资料推送最符合用户需求私教。同时打造出私教社群功能，更加方便用户浏览并选择自己心仪的教练",
    deriveList2_title: "2.0 线上预约功能",
    deriveList2_infoText:
      "线上智能私教提供足不出户即刻健身的体验，我们开发了软件的预约功能，帮助用户直接线上预约私教并选择最近的线下场馆进行更深度的训练",
    deriveList3_title: "3.0 游戏化主题健身跟练",
    deriveList3_infoText:
      "根据美国运动协会组织数据，成年人每周训练少于150分钟则为久坐人群。因此我们搭建了每日主题健身功能，仅9分钟，即可完成打卡。Blackwave想要更多用户以日常锻炼，坚持为出发点去使用魔训",
    deriveList4_title: "4.0 个性化课程匹配机制",
    deriveList4_infoText:
      "以用户训练需求为核心，Blackwave与专业魔训私教共同设计了一套个性化课程匹配机制。其可根据用户选择，制定相对应目标的训练计划，同时也可免去对用户潜在伤病的训练动作",
    deriveList5_title: "5.0 线上购买商城",
    deriveList5_infoText:
      "可以单次购买私教课程，无私教推销，随时锻炼随时预约私教课程。",
    deriveList6_title: "6.0 健身成效可视化/训练搭子",
    deriveList6_infoText:
      "用户可以上传身体数据，练后照片，随时选取进行对比。通过调研，更多的用户认为能够有好友互相督促定会事半功倍。我们增加了训练搭子功能，在魔训上，用户可以匹配到志同道合的训练好友，互相激励督促",

    carouselList1_title:
      "通过科学的信息收集，为用户提供最符合他们自身的训练计划",
    carouselList2_title:
      "通过科学的信息收集，为用户提供最符合他们自身的训练计划",

    project_summarize1: "项目",
    project_summarize2: "总结",
    project_summarize_proj_bj_text:
      "Blackwave设计的魔训APP通过创新打破了传统健身模式，为用户提供了个性化、便捷且灵活的健身体验。结合智能私教服务、个性化课程匹配机制和日常健身打卡，魔训满足了现代用户的多样化需求，重新定义了健身行业的未来发展方向。",

    genduo: "更多客户案例",
  },
  // 案例 -山海传奇
  sanhaiJing: {
    headerTitle: "山海传奇",
    jb_title1: "青少年幼儿体能行业",
    jb_title2: "程序开发",
    left_box_tips:
      " 开发初衷，平台鼓励儿童青少年们通过接触大自然，奔跑，跳跃，玩耍，帮助其他人，找回丢失的童真时代，享受尝试与挑战的乐趣。通过定制化小程序，便捷约课流程，安全监管与指导，促进亲子体验。",
    headerList1_title: "服务内容",
    headerList1_chlie1: "小程序APP开发",
    headerList1_chlie2: "用户体验",
    headerList1_chlie3: "软件维护",
    headerList1_chlie4: "技术决策",

    headerList2_title: "运用平台",
    headerList2_chlie1: "Uniapp",
    headerList2_chlie2: "网页设计",

    headerList3_title: "采用技术",
    headerList3_chlie1: "高德地图API",
    headerList3_chlie2: "电商支付",
    headerList3_chlie3: "一键登录",

    project_bj_title1: "项目",
    project_bj_title2: "背景",

    project_bj_p1:
      " 在当今的科技时代，儿童和青少年花费越来越多的时间在电子设备上，缺乏与大自然的接触和身体上的活动。为了帮助他们找回童年的纯真和探索精神 ，研发推出了山海传奇障碍赛平台。这个项目的目标是为孩子们提供一个充满乐趣和挑战的户外环境，让他们在大自然中奔跑、跳跃、玩耍，同时培养他们的团队合作和互助精神。",
    project_bj_p2:
      "在深度与客户沟通交流后，为了最大程度地减少客户在早期阶段的风险，我们投入了大量的时间进行深入的研究和细致的规划。最终根据其需求，定制一款由 [ 报名交付 ]，[ 报名交付 ]，[ 比赛记录 ]，[ 安全监管 ]，[亲子互动 ]，为一体的小程序产品。",

    challenge_title1: "项目",
    challenge_title2: "挑战",
    challenge_p1:
      "在开发山海传奇障碍赛平台的过程中，客户面临的主要技术挑战包括以下几个方面：参赛人员的二维码生成与绑定、比赛成绩数据的上传和管理、以及用户端的成绩通知和排名海报生成。最终通过创新的技术解决方案，帮助客户彻底解决了这些挑战。",
    challenge_p2_title:
      "1.0 杂乱的现场导致赛后的成绩和名字很难快速地追踪和传递给参赛者",
    challenge_p2:
      " 通过二维码生成技术，实现了参赛者与头盔设备的自动绑定。每位参与者在注册成功后，系统会自动生成一个唯一的参赛二维码。该二维码可通过手机应用程序或电子邮件发送给参赛者，并与其所使用的头盔设备进行实时绑定。",
    challenge_p3_title: " 2.0 数据统计困难，易出错",
    challenge_p3:
      "在比赛完成后，系统会自动收集参赛者的成绩数据，并生成一个详细的文件上传至后台管理系统。为了确保数据的准确性和安全性，采用了高级数据加密技术和自动化上传流程。这一过程不仅简化了管理员的工作负担，还确保了数据传输的高效和安全。",
    challenge_p4_title: "3.0 缺少与参赛者互动和品牌宣传性",
    challenge_p4:
      " 为了提升用户体验，系统在比赛结束后会立即通知参赛者其成绩，并提供详细的排名信息。参赛者可以通过客户端应用程序接收成绩通知，并查看详细的成绩排名。此外，还设计了一项独特的功能：成绩排名海报生成。参赛者可以通过简单的操作生成个性化的排名海报，分享在社交媒体平台上，与朋友和家人分享他们的成就。这不仅增强了用户的参与感和互动性，还为客户的品牌宣传提供了额外的价值。",

    carouselList1_title:
      "根据古老神话为灵感，设计每一道关卡。通过平台交互，简化赛后跟进，提供用户互动以及参与感",

    project_summarize1: "项目",
    project_summarize2: "总结",
    project_summarize_proj_bj_text:
      "通过山海传奇平台的研发，客户公司最终可实现长期进行比赛安排，日程规划的需求，并同时解决了以上多个业务问题，提供了一个全面、创新且高效的解决方案。项目不仅在技术和设计上取得了突破，还在安全、用户体验和市场表现上树立了新的标杆。Blackwave将继续致力于优化平台，推动项目的长期发展，并为儿童和家庭创造更多的价值。",
  },
  // 案例 -学院管理
  college: {
    headerTitle: "学院管理系统",
    jb_title1: "办公室管理",
    jb_title2: "软件开发",
    left_box_tips:
      "实现新生报名整体流程。实现基础数据管理、自动/手动分班、调班、报名流程、缴费、统计等功能，支持自动生成报名单及录取通知书并进行单个或批量打印，支持PC端、学生端、教师端、后勤端、学院端等多端管理。",
    headerList1_title: "服务内容",
    headerList1_chlie1: "小程序APP开发",
    headerList1_chlie2: "用户体验",
    headerList1_chlie3: "软件维护",
    headerList1_chlie4: "技术决策",

    headerList2_title: "运用平台",
    headerList2_chlie1: "java-ruoyi",
    headerList2_chlie2: "后台管理",

    headerList3_title: "采用技术",
    headerList3_chlie1: "高德地图API",
    headerList3_chlie2: "电商支付",
    headerList3_chlie3: "一键登录",

    project_bj_title1: "项目",
    project_bj_title2: "背景",
    project_bj_p1:
      "随着教育信息化的不断发展，高校的招生与学生管理工作逐渐向数字化、智能化转变。传统的招生与管理方式存在诸多不足，如手工录入数据容易出错、人工分班效率低下、信息共享不及时等问题，已无法满足现代高校高效管理和高质量服务的需求。为解决这些问题，我们决定开发一套新生报名及管理系统。",

    project_bj2_title1: "为什么选择",
    project_bj2_title2: "管理系统？",
    project_bj2_p1:
      " 定制一套属于企业自身的OA办公系统能够显著提升工作效率和沟通协作水平。通过针对企业具体需求进行设计，确保功能与实际工作流程高度契合，减少不必要的复杂度。同时，定制系统能够提供更高的数据安全性和合规性，并具备灵活性和可扩展性，以适应企业的持续发展。这样不仅能简化员工操作，提高满意度，还能降低长期成本，增强企业的竞争优势。定制化OA系统是提升企业管理水平和市场竞争力的关键利器。",
    project_bj2_p2_title: "1.0 提高工作效率",
    project_bj2_p2:
      "管理系统软件可以自动化处理许多日常事务，例如数据录入、分班、调班和统计分析等。这大大减少了手工操作的时间和人力成本，提高了工作效率。",
    project_bj2_p3_title: "2.0 增强数据准确性",
    project_bj2_p3:
      " 通过系统化管理，数据的录入、存储和处理更加精确，减少了人为错误，确保了数据的准确性。这对于学生信息、报名记录和财务数据等重要信息尤为关键。",
    project_bj2_p4_title: "3.0 简化管理流程",
    project_bj2_p4:
      "可以实时生成各种统计数据和分析报告，为学校的决策提供有力的数据支持。例如，可以快速查看报名人数、分班情况、缴费情况等，从而做出更科学的管理决策。",
    project_bj2_p5_title: "4.0 增强安全性",
    project_bj2_p5:
      "软件通常具有完善的数据安全保护措施，可以防止数据泄露和丢失。通过权限管理，不同角色的用户只能访问和操作特定的数据，确保信息的安全性和保密性。",

    project_summarize1: "项目",
    project_summarize2: "总结",
    project_summarize_proj_bj_text:
      "综上所述，在线报名信息化建设通过简化流程、提高数据准确性、规范缴费、减轻工作量、实现“一网通办”以及提供个性化服务等方式为教育领域的数字化转型和升级提供了有力支持。",
  },
  // 案例 - 忍者X 学院
  ninja: {
    headerTitle: "忍者X 学院",
    jb_title1: "青少年幼儿体能行业",
    jb_title2: "品牌设计",
    left_box_tips:
      "忍者X学院是加拿大温哥华一家致力于激发儿童潜力并培养未来领袖的先锋训练中心，老板找到了我们希望设计出一系列的品牌全案，传达出他们的动态训练项目为儿童提供全面发展的机会，希望通过引入创新的忍者训练元素，塑造项目的每一个方面，推动学院使命：激励和赋能每一个孩子",
    headerList1_title: "服务内容",
    headerList1_chlie1: "视觉形象设计",
    headerList1_chlie2: "设计顾问",
    headerList1_chlie3: "公司品牌运营",
    headerList1_chlie4: "技术决策",

    headerList2_title: "运用平台",
    headerList2_chlie1: "实体运营",

    headerList3_title: "采用技术",
    headerList3_chlie1: "3D 建模&渲染",

    project_bj_title1: "项目",
    project_bj_title2: "背景",
    project_bj_p1:
      " NINJA X是一家坐落于加拿大温哥华列治文的儿童技能发展与培训中心。以独特且充满趣味障碍和跑酷设计，致力于提升孩子的身体素质，并激发他们的心智成长。",
    project_bj_p2:
      "在场地建立初期，Blackwave被委任为品牌形象设计的合作伙伴，负责包括品牌主色调、场馆视觉设计、品牌LOGO以及广告运营宣传设计等多个方面的策划和实施。我们通过创新和专业的设计，塑造了一个充满活力和吸引力的品牌形象。",

    project_bj1_header: "从0-",
    project_bj2_header: "1",
    project_bj1_p1:
      "在初期设计阶段，我们实地勘察现场场地，发现整体呈现出大面积的浅木黄色，采光良好且明亮。经过仔细考虑，我们决定保留地板的原有色调，以保持自然和温馨的氛围。",
    project_bj1_p2_1:
      "考虑到该品牌的主要受众是10岁以下的儿童，我们希望选择更加童趣且富有活力的颜色，以吸引孩子们的注意力，并激发他们的兴趣。经过对忍者形象元素的深度研究，我们结合了色彩心理学和整体色调的视觉协调性，最终将品牌的主色调确定为绿色和橙色。 绿色（",
    project_bj1_p2_2: "）象征着活力与成长，橙色（ ",
    project_bj1_p2_3:
      "）代表着热情与活泼，这种搭配不仅符合儿童心理特征，还为场馆增添了一种生动的视觉效果。",

    project_bj3_header: "效果",
    project_bj4_header: "呈现",
    project_bj2_p1:
      "在确定了主色调为绿色和橙色后，进一步推进了场馆内器械的颜色搭配与设计。目标是确保每个元素都能完美地融入整体环境，并提升场馆的视觉吸引力和功能性。",
    project_bj2_p2_title: "颜色搭配与设计",
    project_bj2_p2:
      "根据不同的区域功能和使用人群，细致地规划了器械的色彩方案。在儿童活跃区域，我们运用了鲜亮且对比度强烈的色彩组合，以激发孩子们的好奇心和探索欲望。我们特别注意色彩的心理影响，选择了对孩子友好且能激发创造力的色彩组合。这种色彩策略不仅仅是为了美观，更是为了提高孩子们的参与度和积极性。",
    project_bj2_p3_title: "场景渲染与效果展示",
    project_bj2_p3:
      "我们运用3D建模技术和渲染软件，通过精细化的平面图和详细的场景渲染模拟出真实的空间效果，使客户在未亲临现场的情况下，也能全方位地感受到场馆的效果。",
    project_bj5_header: "LOGO",
    project_bj6_header: "设计",

    project_bj7_header: "更多",
    project_bj8_header: "内容",
    carouselList1_title: "实际场馆效果展示",

    project_summarize1: "项目",
    project_summarize2: "总结",
    project_summarize_proj_bj_text:
      " 通过 NINJA X品牌设计的项目，我们见证了一个全面、创新且高效的儿童技能发展中心的兴起。独特的忍者形象设计、鲜艳的色彩搭配和精心规划的场馆布局，我们将场馆的视觉吸引力把控到位。接下来我们还将继续与 NINJA X 合作，共同打造接下来最新的项目需求。让我们拭目以待吧！",
  },

  // 博客首页
  blolist: {
    header_title1: "探索",
    header_title2: "观点，聚焦行业",
    gengduowenz: "更多文章浏览",
    header_tips:
      "带您深入解读互联网领域的最新动态与创新趋势，助力企业紧跟行业风向，实现数字化升级",
  },
  // 博客 - 从线上出发：如何打造一个令人难忘的品牌形象
  blobrand: {
    header_title: "从线上出发：如何打造一个令人难忘的品牌形象",
    proj_bj_p1:
      "在这个信息爆炸的时代，线上品牌形象的塑造比以往任何时候都更为重要。随着越来越多的企业转向数字化，品牌已经不再是简单的名称或标识，而是一种可以传达企业核心价值和个性的媒介。品牌形象的构建也不再仅仅依赖于线下的广告、活动和口碑，更多的线上平台为企业提供了广阔的范围，使得品牌形象的塑造变得更加多样化和复杂。这不仅关系到客户的首次接触，还影响到他们未来的忠诚度。因此，理解品牌的重要性，是企业在竞争中获得优势的关键。",
    proj_bj_p2_title:
      "我们首先要明确，品牌就是客户决策的核心，能够简化客户的决策过程。在B2B和B2C市场中。",
    proj_bj_p2:
      " 客户在选择产品或服务时，往往要在无数的选择中做出决定。一个强大、清晰的品牌能够减少客户在评估时的犹豫。例如，知名企业如IBM或埃森哲的高溢价产品依然受到客户青睐，正是因为它们通过品牌传递了强大的市场实力和可靠性。客户看到这些品牌时，知道他们会获得与品牌承诺一致的产品或服务体验。因此，品牌不仅是在市场中的一个标记，更是客户信任的象征。",
    proj_bj_p3:
      "所以在品牌塑造中，企业需要让客户明确理解他们提供的价值。品牌传递的并不仅仅是一个产品的功能或服务，而是它如何满足客户的需求。对于很多企业或者顾客来说，产品本身可能是同质化的，然而一个有吸引力的品牌能够让客户快速做出选择，甚至愿意为其支付更高的价格，因为品牌承载了产品背后的承诺和质量保障。",
    proj_bj_p4:
      "品牌的力量不仅体现在短期内的销售提升，还会对企业的长期增长产生深远的影响。通过品牌，企业不仅可以提升市场知名度，还能够在客户心中建立起长久的信任关系。客户一旦对某个品牌产生信任，往往会更容易复购或推荐给其他潜在客户，事实上数据显示，与品牌有过积极体验的客户，更愿意在不同场合为品牌背书，这为企业带来了长期的口碑效应。",
    proj_bj_p5_title:
      " 此外，品牌的积累还能大大降低企业的获客成本。随着客户对广告的敏感性提高，",
    proj_bj_p6:
      "许多人在做出购买决定前会主动搜索品牌信息，那么企业的官网和其他第三方平台的展示就尤为重要，",
    proj_bj_p7:
      "一个强大的品牌形象能够让企业在众多竞争者中脱颖而出，从而减少大量的广告支出和营销成本。",
    proj_bj_p8_title:
      "品牌的另一重要维度在于内容营销。在当今信息过载的时代，品牌需要通过优质内容与客户建立情感连接。",
    proj_bj_p9:
      "内容不仅仅是简单的广告或推销，而是企业用来传达其价值观、愿景和专业知识的媒介。",
    proj_bj_p10:
      "例如，企业可以通过分享行业见解、客户案例或解决方案，展示其在特定领域的专业性。优质的内容能够增加品牌的可信度，吸引潜在客户，并帮助品牌在客户心中占据一席之地。",
    proj_bj_p11:
      "在Blackwave，我们深知故事的力量，通过展示客户的成功案例和设计过程，我们不仅是在展示我们的技术能力，更是在向潜在客户传达一种信任和可信度。每个品牌背后都有一个独特的故事，而我们帮助他们讲述这个故事，用真实的成功经验打动未来的客户。",
    proj_bj_p12:
      "品牌不仅要被看到，还要与客户互动。社交媒体为企业提供了一个绝佳的平台，帮助企业直接与客户建立联系，获取反馈并增强参与感。与传统广告相比，社交媒体互动能够让客户感受到品牌的亲和力，并增强他们的忠诚度。在某些情况下，企业可以通过社交媒体发起用户生成内容（UGC）的活动，进一步增强品牌的互动性。例如，某些品牌通过邀请客户分享使用产品的照片或体验，并在社交平台上使用品牌的特定标签，提升品牌曝光率和用户的参与感。这种互动能够让客户感受到自己是品牌的一部分，从而进一步增强品牌的忠诚度。",
    proj_bj_p13:
      "数字化的最大优势之一就是数据的可追踪性。通过分析客户行为数据，企业能够更好地了解品牌在市场中的表现。",
    proj_bj_p14:
      "例如，分析某个广告的点击率或某篇社交媒体内容的参与度，企业能够迅速调整策略，确保品牌传播的有效性。这种数据驱动的品牌优化方式，帮助企业在快速变化的市场中保持竞争力。",
    proj_bj_p15:
      "总之，品牌不仅是企业与客户之间的桥梁，更是企业在市场中长期成功的基础。无论是通过清晰的品牌定位，还是一致的视觉设计和内容营销，品牌的力量无处不在。而企业若能掌握这些要素，不仅能够赢得客户的信任，还能在未来的竞争中立于不败之地。",
  },
  // 博客 - 企业官网策划：如何通过精准内容策略推动ToB业务增长
  blotob: {
    header_title: "企业官网策划：如何通过精准内容策略推动ToB业务增长",
    proj_bj_p1:
      "在创建ToB企业的官网时，网络策划是成功的关键步骤之一。今天我们重点来讲解一下如何通过科学的策划来提升官网的效果，确保对于企业和品牌来说，官网不仅仅是一个展示平台，更是一个高效的营销工具，确保能够有效吸引客户并提升企业的品牌和转化率。",
    proj_bj_p2_title: "1. 确定官网目标 ",
    proj_bj_p2:
      "在开始网站策划之前，企业首先需要明确官网的目标。官网可以承担多个角色，以下是常见的几类官网目标：",
    proj_bj_p2_chil1:
      "品牌展示：通过官网传达企业品牌形象，提升用户对企业的信任。",
    proj_bj_p2_chil2:
      "流量获取：通过数字化营销手段（SEO、SEM等）吸引访客，提升网站流量。",
    proj_bj_p2_chil3:
      "线索转化：通过丰富的内容和清晰的注册引导，促使访客留下个人信息，转化为潜在客户。",
    proj_bj_p2s:
      " 明确目标后，网站策划就可以围绕这些目标展开，确保每个页面、每个功能的设计都服务于目标的实现。",

    proj_bj_p3_title: "2. 网站类型选择",
    proj_bj_p3: "根据企业的定位和目标，官网通常可以分为以下几种类型： ",
    proj_bj_p3_chil1:
      "名片型网站：内容简洁，展示企业基本信息，如企业简介、联系方式、产品/服务列表等，主要用于树立企业的在线形象。",
    proj_bj_p3_chil2:
      "品牌型网站：主要用于品牌推广，设计精良，通过展示企业的实力和案例，帮助访客建立品牌认知和信任感。",
    proj_bj_p3_chil3:
      "营销型网站：不仅展示企业信息，还需要引导访客参与更多的互动，比如注册、下载资料、填写表单，最终转化为潜在客户。",
    proj_bj_p3s: "选择合适的官网类型，决定了网站整体的内容架构和功能设计。",

    proj_bj_p4_title: "3. 网站架构设计",
    proj_bj_p4:
      "官网的架构设计决定了访客的浏览路径和体验。一般情况下，ToB企业的官网结构可以分为以下几个核心模块：",
    proj_bj_p4_chil1:
      "首页：展示企业的核心价值、产品/服务的亮点以及快速导航至重要页面。",
    proj_bj_p4_chil2: `产品介绍：详细介绍企业的产品或服务，明确其功能、优势及适用场景。
              解决方案：根据不同客户的业务痛点，提供有针对性的解决方案，展示企业的专业性。`,
    proj_bj_p4_chil3:
      "客户案例：通过成功案例展示企业的真实合作经验，增强客户信任。",
    proj_bj_p4_chil4:
      "关于我们：介绍企业的发展历程、创始团队、公司荣誉等，增强企业的权威性和信任度。",
    proj_bj_p4s: `            在设计网站架构时，要特别注意访客的浏览路径，使得访客能够轻松地找到他们所需的信息。
            一个清晰、合理的架构能够提升访客的使用体验，降低跳出率。`,

    proj_bj_p5_title: "4. 域名与服务器选择",
    proj_bj_p5:
      "官网的架构设计决定了访客的浏览路径和体验。一般情况下，ToB企业的官网结构可以分为以下几个核心模块：",
    proj_bj_p5s:
      "域名是官网的“门牌号”，选择简短、易记并能与企业品牌相关的域名至关重要。服务器则决定了网站的稳定性和安全性，选择可靠的服务器能够确保网站长期运行稳定，并支持流量的增长。",

    proj_bj_p6_title: "5. 网站内容策划",
    proj_bj_p6:
      "内容是网站的核心，良好的内容策划能够吸引访客停留并转化。以下是几个重点内容板块：",
    proj_bj_p6_chil1:
      " 产品/服务介绍：使用FABE法则（功能、优势、利益、证据）进行内容策划。将产品功能明确描述，并展示产品对客户的实际价值。",
    proj_bj_p6_chil2: `解决方案：详细说明企业如何解决客户的痛点，展示企业的专业性和定制化服务。`,
    proj_bj_p6_chil3:
      "客户案例：通过成功案例展示企业如何帮助其他客户取得成果。可以包含业务挑战、解决方案、结果和客户反馈等内容，增加可信度。",
    proj_bj_p6s: ` 内容不仅要丰富，还要有明确的CTA（行动号召），如“立即注册”、“获取免费演示”等，引导访客采取下一步行动。`,

    proj_bj_p7_title: "6. 用户体验与设计",
    proj_bj_p7:
      "一个优秀的网站不仅需要内容丰富，还需要用户体验友好。通过合理的视觉设计、简洁的导航和快速的响应，提升用户的浏览体验。响应式设计尤为重要，确保网站在不同设备（PC、手机、平板）上都能良好展示。",
    proj_bj_p7_chil1: " 设计时需特别注意以下几点：",
    proj_bj_p7_chil2: `视觉统一：确保官网的颜色、字体、图标等视觉元素统一，符合企业品牌的调性。`,
    proj_bj_p7_chil3:
      "易用性：减少页面的点击次数，确保访客可以快速找到所需信息。",
    proj_bj_p7_chil4:
      " 响应速度：通过压缩图片、优化代码等方式，提高网站加载速度，减少访客流失。",
    proj_bj_p7_chil5:
      "关于我们：介绍企业的发展历程、创始团队、公司荣誉等，增强企业的权威性和信任度。",

    proj_bj_p8_title: " 7. 数据埋点与反馈机制",
    proj_bj_p8:
      "网站策划还需要考虑如何通过数据埋点来获取用户行为数据。这些数据可以帮助企业了解用户在网站的浏览路径、点击内容、停留时间等，从而进一步优化网站内容和功能。通过持续的数据分析，企业可以调整网站运营策略，以更好地迎合用户需求。",

    proj_bj_p9_title: "8. 持续运营与优化",
    proj_bj_p9:
      "网站策划并不是一次性的工作，网站上线后需要持续优化。根据用户反馈和数据分析，定期更新内容、优化页面布局、调整营销策略，确保网站始终保持竞争力。定期的SEO优化也能帮助提升网站在搜索引擎中的排名，吸引更多的自然流量。",
    proj_bj_p10_title: "结语",
    proj_bj_p10:
      " 没错，以上提到的内容策略只是官网策划中的冰山一角。就像在大海航行时，表面上的波涛汹涌让人容易看见，而深海中的“黑浪”，那些隐藏的细节和潜在的挑战，往往才是真正的考验。",
    proj_bj_p11:
      "一个成功的官网策划不仅仅是视觉和功能的设计，更在于如何通过内容、架构、引导访客等方式，实现企业的业务目标。通过科学的策划、合理的布局和持续的运营优化，官网可以成为企业营销和品牌推广的强大工具，帮助企业快速获得精准线索并提升转化率。",
  },
  // 博客 - 从用户体验到数据分析： 构建成功ToC网站的全攻略
  bloaddtob: {
    header_title: "从用户体验到数据分析： 构建成功ToC网站的全攻略",
    proj_bj_p1:
      "在打造一个面向消费者的ToC网站时，官网不仅仅是个展示平台，它就像一位活跃的品牌大使，随时准备为用户提供便利、吸引他们互动，并促成行动。设计这样一个网站时需要考虑哪些关键的步骤呢？既能确保它不仅美观，还能有效提升品牌认知度和转化率，那就让我们继续探索其中的奥秘。",
    proj_bj_p2_title: "1. 明确核心目标",
    proj_bj_p2:
      "首先，网站的角色是什么？是为了展示产品、增强品牌、还是增加互动？就像一位友好的店员，你希望它帮助用户快速找到所需商品，并鼓励他们采取行动，所以我们的核心目标可以分成这三类。",

    proj_bj_p3:
      "产品销售：通过优化购物流程，促成快速购买。为了提升产品销售，必须简化购物流程，确保用户可以快速完成购买。通过设计直观的产品分类、提供清晰的产品详情页面、简化购物车操作和结账流程，减少购买的步骤和时间。此外，支持多种支付方式和快速结账选项，如“一键购买”，让用户毫无阻碍地完成交易。",
    proj_bj_p4:
      "品牌宣传：提升品牌认知，吸引消费者互动。品牌宣传的核心在于展示品牌价值和形象，通过官网的设计传递品牌故事、文化和理念。通过高质量的内容，如品牌故事、客户见证、媒体报道等，增强消费者对品牌的情感联系。同时，网站的视觉设计应与品牌形象一致，帮助消费者建立对品牌的认知与信任。",
    proj_bj_p5:
      "用户参与：通过吸引用户注册、分享等行动，增加用户粘性。用户参与是通过引导用户进行互动来增加其对网站的粘性。可以通过注册会员、积分系统、分享功能等方式，鼓励用户积极参与。用户生成内容（如评论、晒单等）、社交媒体整合，以及促销活动也有助于用户在网站中保持活跃。",

    proj_bj_p6_title: " 2. 选择合适的网站类型",
    proj_bj_p6:
      "就像不同风格的商店吸引不同的客户，你的网站也应该根据业务类型量身打造。是否需要一个电商平台让用户轻松购买，或者一个品牌宣传网站传递你的故事？",
    proj_bj_p7:
      "展示型网站：这类网站以展示品牌形象和业务信息为主，通常通过精美的设计和高质量的图片、视频等内容来吸引用户。适用于创意机构、艺术家或希望展示专业服务的公司，帮助建立品牌信任和与用户的情感连接。",
    proj_bj_p8:
      "预约型网站：主要用于提供在线预约和时间安排的服务，如健身房、诊所和培训机构等。这种网站支持实时日历、在线支付和自动提醒功能，使预约过程更方便、快捷，同时减少人工操作的负担。",
    proj_bj_p9:
      "电商型网站：以销售产品或服务为核心，包含购物车、产品展示、在线支付和用户账户系统等功能。适用于零售商或品牌店铺，通过简化的购买流程和多种支付选项来提升用户购物体验和转化率。",

    proj_bj_p10_title: "3. 用户体验是关键",
    proj_bj_p10:
      "没有人愿意在杂乱无章的商店购物，网站也是如此。通过流畅的导航和清晰的设计，引导用户在产品、信息间轻松穿梭。用户体验设计（UX设计），可以让你的官网就像一家整洁、舒适的商店，吸引用户留下并购买，所以对自己产品的定位清晰还有前期对用户的深刻分析是很关键的。",
    proj_bj_p11_title: "4. 选对域名与服务器",
    proj_bj_p11:
      "就像街头店铺的招牌一样，域名应该简洁、易记，而服务器就像后台的物流，确保网站运行流畅、不掉线。当然，Blackwave的服务器配置保证你的“店铺”在高峰时段依然能顺利接待每一位访客。",
    proj_bj_p12_title: "5. 内容策划：吸引并打动用户",
    proj_bj_p12:
      "每个页面都是与访客的对话。通过高质量的内容（产品描述、用户评价、促销活动）与SEO结合，确保用户和搜索引擎都能轻松找到你。你需要一直产出高质量的输出去增加你的网页权重以及用户留存率，一定要制定一个全套的策略方案。",
    proj_bj_p13_title: " 6. 吸引用户行动的CTA设计",
    proj_bj_p13:
      "每个页面都应该有明确的行动号召（CTA）。像“立即购买”或“加入购物车”这样的按钮是你与用户之间的桥梁，醒目、诱人的设计可以大幅提升点击率。好的网站是可以预测用户的每一个阅览流程和点击方式，确保用户体验流畅自然，轻松完成转化。",

    proj_bj_p14_title: "7. 数据埋点与反馈机制 ",
    proj_bj_p14:
      "监测用户在网站的行为路径，找出哪些页面、按钮吸引了最多点击，或哪些步骤让用户中途离开。通过数据优化，提升用户体验和转化率。一定要定期整理这些数据，分析和优化，帮助你实时跟踪用户动向，调整网站策略，抓住每一个潜在客户。",
    proj_bj_p15_title: "8. 持续运营与优化",
    proj_bj_p15:
      "网站如同一家店铺，需要定期更新陈列，推出新品，甚至重新装修。通过持续的内容更新和SEO优化，确保用户始终对你感兴趣。",
    proj_bj_p16_title: "结语",
    proj_bj_p16:
      "以上这些就是一个合格的面向用户的网站应该注意的事项，从自身定位去搭建框架，再从框架中找寻细节，以及定期的推动和优化。就像在大海航行时，表面上的波涛汹涌让人容易看见，而深海中的“黑浪”，那些隐藏的细节和潜在的挑战，往往才是真正的考验。",

    proj_bj_p17:
      "一个成功的网站策划不仅仅是视觉和功能的设计，更在于如何通过内容、架构、引导访客等方式，实现企业的业务目标。通过科学的策划、合理的布局和持续的运营优化，顺利帮助用户获得良好的视觉与消费体验。",
  },
  //联系我们
  contact: {
    header_title1: "为你的项目来一场",
    header_title2: "专属定制？",
    header_tips:
      "感谢您在我们网站上提交咨询！我们的商务经理将在24小时内与您联系，并安排一次会议，详细讨论您的项目需求和业务目标。之后，我们的方案专家会为您提供一份初步的定制化解决方案，并涵盖相关时间和费用信息。",

    form_header_box1: "共创",
    form_header_box2: "实现蓝图",

    name_input: "如何称呼您",
    name_inputs: "联系我们",
    email_input: "您的邮箱",
    type_input: "定制产品类型",
    type_input_1: "如：产品开发",
    type_input_2: "软件定制开发",
    type_input_3: "网站定制开发",
    type_input_4: "品牌设计",
    type_input_5: "一站式搭建",
    messages_input: "您的项目需求",
    SubmitForm: "立即联系",
  },
  customized: {
    title: "为你的项目来一场",
    title1: "专属定制？",
    tipe: "为全球范围企业打造最符合 “你” 的产品定制",
    tipe_ri: "你的下一步，让我们一起完成",
    btn: "联系我们",
  },
};
