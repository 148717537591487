import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import { BasicLayout } from "@/layouts";

const routesP = [
  {
    path: "/",
    name: "Index",
    component: BasicLayout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../pages/pc/index.vue"),
      },
      {
        path: "/case",
        name: "projectCase",
        component: () => import("../pages/pc/projectCase/index"),
      },
      {
        path: "/sanhaiJing",
        name: "projectCase",
        component: () => import("../pages/pc/projectCase/sanhaiJing"),
      },
      {
        path: "/moXun",
        name: "moXun",
        component: () => import("../pages/pc/projectCase/moXun"),
      },
      {
        path: "/ninja",
        name: "ninja",
        component: () => import("../pages/pc/projectCase/ninja"),
      },
      {
        path: "/college",
        name: "college",
        component: () => import("../pages/pc/projectCase/college"),
      },

      {
        path: "/contact",
        name: "contact",
        component: () => import("../pages/pc/contact.vue"),
      },
      {
        path: "/servicehome",
        name: "servicehome",
        component: () => import("../pages/pc/servicehome.vue"),
      },
      {
        path: "/software",
        name: "software",
        component: () => import("../pages/pc/service/software.vue"),
      },
      {
        path: "/website",
        name: "website",
        component: () => import("../pages/pc/service/website.vue"),
      },
      {
        path: "/brand",
        name: "brand",
        component: () => import("../pages/pc/service/brand.vue"),
      },
      {
        path: "/blolist",
        name: "blolist",
        component: () => import("../pages/pc/blolist.vue"),
      },
      {
        path: "/blobrand",
        name: "blobrand",
        component: () => import("../pages/pc/blo/brand.vue"),
      },
      {
        path: "/blotob",
        name: "blotob",
        component: () => import("../pages/pc/blo/tob.vue"),
      },
      {
        path: "/bloaddtob",
        name: "bloaddtob",
        component: () => import("../pages/pc/blo/addtop.vue"),
      },
    ],
  },
];

const routesM = [
  {
    path: "/",
    name: "Index",
    component: BasicLayout,
    redirect: "/",
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../pages/mobile/index.vue"),
      },
      {
        path: "/case",
        name: "projectCase",
        component: () => import("../pages/mobile/projectCase/index"),
      },
      {
        path: "/sanhaiJing",
        name: "projectCase",
        component: () => import("../pages/mobile/projectCase/sanhaiJing"),
      },
      {
        path: "/moXun",
        name: "moXun",
        component: () => import("../pages/mobile/projectCase/moXun"),
      },
      {
        path: "/ninja",
        name: "ninja",
        component: () => import("../pages/mobile/projectCase/ninja"),
      },
      {
        path: "/college",
        name: "college",
        component: () => import("../pages/mobile/projectCase/college"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../pages/mobile/contact.vue"),
      },
      {
        path: "/servicehome",
        name: "servicehome",
        component: () => import("../pages/mobile/servicehome.vue"),
      },
      {
        path: "/software",
        name: "software",
        component: () => import("../pages/mobile/service/software.vue"),
      },
      {
        path: "/website",
        name: "website",
        component: () => import("../pages/mobile/service/website.vue"),
      },
      {
        path: "/brand",
        name: "brand",
        component: () => import("../pages/mobile/service/brand.vue"),
      },
      {
        path: "/blolist",
        name: "blolist",
        component: () => import("../pages/mobile/blolist.vue"),
      },
      {
        path: "/blobrand",
        name: "blobrand",
        component: () => import("../pages/mobile/blo/brand.vue"),
      },
      {
        path: "/blotob",
        name: "blotob",
        component: () => import("../pages/mobile/blo/tob.vue"),
      },
      {
        path: "/bloaddtob",
        name: "bloaddtob",
        component: () => import("../pages/mobile/blo/addtop.vue"),
      },
    ],
  },
];

var routes = [];
if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  routes = routesM;
} else {
  routes = routesP;
}

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }; // 每次路由变化后滚动到顶部
  },
});

// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }
router.afterEach((to, from) => {
  window.scrollTo(0, 0); // 确保页面滚动到顶部
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  setTimeout(() => {
    next();
  }, 100);
});

export default router;
